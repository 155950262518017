import React, { useEffect, useRef, useState } from "react";
import { ChatBox, ChatBoxMsg, MessageContainer } from "./Styles";
import { Avatar, AvatarLetter, AvatarImage, Images } from "../StyledComponents";
import { Div, FileInput, Text } from "../../StyledComponents";
import moment from "moment";
import DocType from "../../../Assets/images/docType.png";
import { toast } from "react-toastify";
import Error from "../../StyledComponents/Toastify/Error";
import { Div2 } from "../Styles";

const ChatBody = ({
  data,
  handlePreview,
  setViewType,
  setSelectedFile,
  isValidFileUploaded,
  hightlightText,
  navigateSearch,
  steps,
  steps1,
}) => {
  const audioURLs = [];
  const inputEl = useRef(null);
  const messageEl = useRef(null);
  const highLightEl = useRef([]);
  const [lp, setLp] = useState(0);
  const [drop, setDrop] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [prevAudio, setPrevAudio] = useState(null);
  var imageUrl =
    process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/MessageImages/";
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  DBhost = "default";

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  var audioURL = backurl + "/sites/" + DBhost + "/Voice_recordings/";

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrop(true);
    } else if (e.type === "dragleave") {
      setDrop(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const valid = e.dataTransfer.files[0].type.split("/")[1];

    if (
      valid === "pdf" ||
      valid === "png" ||
      valid === "jpeg" ||
      valid === "jpg"
    ) {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        if (isValidFileUploaded(file)) {
          setSelectedFile(file);
        }
        setDrop(false);
      }
    } else {
      toast(<Error msg="File not supported......" />, {
        containerId: "B",
        className: "error_badge",
      });
      setDrop(false);
    }
  };

  const handleSearchScrolling = () => {
    if (highLightEl.current.length > 0) {
      if (navigateSearch === "up") {
        if (lp - 1 >= 0) {
          highLightEl.current[lp - 1].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
          let a = lp - 1;
          setLp(a);
        }
      } else if (navigateSearch === "down") {
        if (lp + 1 <= highLightEl.current.length - 1) {
          highLightEl.current[lp + 1].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
          let a = lp + 1;
          setLp(a);
        }
      } else {
        highLightEl.current[highLightEl.current.length - 1].scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  };

  const hadRef = (el, array) => {
    array.map((row) => {
      if (row.includes(hightlightText)) {
        if (el && !highLightEl.current.includes(el)) {
          if (el.id !== undefined && el.id === hightlightText) {
            highLightEl.current.push(el);
          } else {
            highLightEl.current.pop(el);
          }
        } else {
          highLightEl.current.pop(el);
        }
      }
    });
  };

  const handleMessage = (msg) => {
    if (msg === null) {
      return null;
    }
    let arr = msg.split(" ");
    if (hightlightText !== "" && hightlightText !== undefined) {
      return (
        <span ref={(el) => hadRef(el, arr)} id={hightlightText}>
          {arr.map((row, i) =>
            row.includes(hightlightText) ? (
              <>
                <mark id={hightlightText} key={i}>
                  {row}
                </mark>
                &nbsp;
              </>
            ) : (
              <>
                <span key={i}>{row}</span> &nbsp;
              </>
            )
          )}
        </span>
      );
    } else {
      return (
        <>
          <span>
            {arr.map((row, i) => (
              <>
                <span key={i}>{row}</span> &nbsp;
              </>
            ))}
          </span>
        </>
      );
    }
  };

  const handlePlay = (audioElement) => {
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio && currentAudio.pause();
    }
    setCurrentAudio(audioElement);
    // setPrevAudio(currentAudio);
    audioElement.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  };

  const handlePause = () => {
    if (prevAudio) {
      prevAudio.pause();
      setPrevAudio(null);
    }
  };

  useEffect(() => {
    messageEl.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    setDrop(false);
    setLp(highLightEl.current.length);
  }, [data, hightlightText]);

  useEffect(() => {
    handleSearchScrolling();
  }, [steps, steps1, hightlightText]);

  return (
    <>
      <MessageContainer
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        drop={drop}
      >
        <FileInput
          ref={inputEl}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          // accept=".jpg, .jpeg, .png, .pdf, .gif"
          accept=".png, .pdf, .jpg, .jpeg, .gif"
          multiple={true}
        />

        {data.length !== 0 ? (
          data.map(
            (item, index) =>
              item.message !== null && (
                <ChatBox
                  user={item.sender === "Patient" ? true : false}
                  type={item.type}
                  key={index}
                >
                  {item.type !== "date" && item.sender !== "Patient" && (
                    <>
                      {item.prov_image === null ? (
                        <Avatar width="32px" height="32px">
                          <AvatarLetter top="8px" left="0px">
                            {item.prov_name.charAt(0).toUpperCase()}
                          </AvatarLetter>
                        </Avatar>
                      ) : (
                        <AvatarImage
                          src={File_URL + item.prov_image}
                          alt="image-failed"
                          loading="lazy"
                          width="32px"
                          height="32px"
                          objectFit="unset"
                        />
                      )}
                    </>
                  )}
                  <ChatBoxMsg
                    user={item.sender === "Patient" ? true : false}
                    type={item.type}
                    className="tester"
                  >
                    {item.type === "date" &&
                      item.message !== null &&
                      moment(item.date).calendar(null, {
                        sameDay: "[Today]",
                        nextDay: "[Tomorrow]",
                        nextWeek: "dddd",
                        lastDay: "[Yesterday]",
                        lastWeek: "dddd,MMMM D",
                        sameElse: "DD/MM/YYYY",
                      })}

                    {item.imageUrl !== null && item.type === "audio" && (
                      <Div2
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div key={index}>
                          <audio
                            style={{ height: "40px", width: "250px" }}
                            controls
                            ref={(audioElement) =>
                              (audioURLs[index] = audioElement)
                            }
                            onPlay={() => handlePlay(audioURLs[index])}
                            onPause={handlePause}
                          >
                            <source
                              src={audioURL + item.imageUrl}
                              type="audio/wav"
                            />
                          </audio>
                        </div>
                        <Text
                          width="unset"
                          margin="3px 0 0 10px"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </Div2>
                    )}

                    {item.type === "text" && (
                      <>
                        <Text
                          width="unset"
                          fontFamily="Inter"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="17px"
                          textAlign="unset"
                          color="unset"
                          display="inline"
                          MaxWidth="391px"
                          wordBreak
                        >
                          {handleMessage(item.message)}
                        </Text>
                        <Text
                          width="unset"
                          margin="0 0 0 10px"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </>
                    )}
                    {item.type === "image" && (
                      <>
                        <Images
                          cursor="pointer"
                          MaxWidth="221px"
                          MaxHeight="137px"
                          objectFit="cover"
                          display="block"
                          src={item.imageUrl && imageUrl + item.imageUrl}
                          alt=" "
                          onClick={() => {
                            handlePreview(imageUrl + item.imageUrl);
                            setViewType("image");
                          }}
                        />
                        <Text
                          fontFamily="'Inter',sab-serif"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="17px"
                          textAlign="right"
                          color="unset"
                          display="inline"
                        >
                          {item.message}
                        </Text>
                        <Text
                          width="unset"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                          margin="0 0 0 10px"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </>
                    )}
                    {item.type === "doc" && (
                      <>
                        <Images
                          cursor="pointer"
                          MaxWidth="221px"
                          MaxHeight="137px"
                          objectFit="cover"
                          display="block"
                          src={DocType}
                          alt=" "
                          onClick={() => {
                            handlePreview(imageUrl + item.imageUrl);
                            setViewType("doc");
                          }}
                        />
                        <Text
                          fontFamily="Inter"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="17px"
                          textAlign="unset"
                          color="unset"
                          display="inline"
                        >
                          {item.message}
                        </Text>
                        <Text
                          width="unset"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                          margin="0 0 0 10px"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </>
                    )}
                  </ChatBoxMsg>
                  {item.type !== "date" && item.sender === "Patient" && (
                    <>
                      {item.pat_image === null ? (
                        <Avatar width="32px" height="32px" minWidth="32px">
                          <AvatarLetter top="8px" left="0px">
                            {item.pat_name.charAt(0).toUpperCase()}
                          </AvatarLetter>
                        </Avatar>
                      ) : (
                        <AvatarImage
                          src={File_URL + item.pat_image}
                          alt="image-failed"
                          loading="lazy"
                          width="32px"
                          height="32px"
                          minWidth="32px"
                          objectFit="unset"
                        />
                      )}
                    </>
                  )}
                </ChatBox>
              )
          )
        ) : (
          <Div
            display="flex"
            justifyContent="center"
            fontWeight="500"
            fontSize="14px"
            lineHeight="17px"
            color="#8A969F"
            backColor="unset"
          >
            No Messages....
          </Div>
        )}
        {data.map(
          (item, index) =>
            item.message === null &&
            item.status === "2" && (
              <Div
                display="flex"
                justifyContent="center"
                fontWeight="500"
                fontSize="14px"
                lineHeight="17px"
                color="#8A969F"
                backColor="unset"
                key={index} // Don't forget to add a unique key
              >
                No Messages....
              </Div>
            )
        )}
        <Div ref={messageEl}></Div>
      </MessageContainer>
    </>
  );
};

export default ChatBody;
