import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const TitleBlock = styled.div`
  display: inline;
  align-items: center;
`;

export const Title = styled.h2`
  color: ${(p) => p.color};
  display: inline;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 44px;
`;

export const SubTitle = styled.h4`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  align-items: center;
  color: #667085;
  margin-top: 16px;
`;
export const ClientButton = styled(NavLink)`
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 16px;
  display: inline-block;
  width: 200px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  text-decoration: none;
  margin-right: ${(p) => p.marginright && p.marginright};
  &:hover {
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border: 1px solid #3f8cff;
  }
`;

export const Status = styled.h5`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  color: #000000;
  margin-bottom: 9px;
  margin-top: 12px;
  text-align: center;
`;
