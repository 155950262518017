import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetList } from "../../StateManagement/Reducers/HealthProfileState";

import TopNav from "../TopNav";
import Lists from "./Lists";
import Loading from "../StyledComponents/Loading";

import { BodyContainer } from "../StyledComponents";
import { SubHeading } from "./styles";

const HealthProfile = () => {
  const dispatch = useDispatch();
  const [type] = useState("medical_problem");

  const LoadingRes = useSelector((state) => state.HealthProfile.HealthLoading);

  useEffect(() => {
    dispatch(GetList(type));
  }, [type]);

  return (
    <>
      <TopNav title="Health Profile" />
      {LoadingRes ? (
        <Loading />
      ) : (
        <BodyContainer paddingLeft="40px" paddingTop="12px">
          <SubHeading>Problems List</SubHeading>

          <Lists list={"Problems"} />
        </BodyContainer>
      )}
    </>
  );
};

export default HealthProfile;
