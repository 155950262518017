import React from "react";
import WelcomeRight from "../WelcomeRight";
import LoginLeft from "../LoginLeft";
import { Div } from "../Styles";
import { Container } from "../StyledComponents";

const Welcome = () => {
  return (
    <Container Flex height="100vh" minWidth="1024px">
      <>
        <Div width="50%" bg_color="#F8FAFB" position="relative">
          <LoginLeft />
        </Div>
        <Div width="50%" bg_color="#FFFFFF">
          <WelcomeRight />
        </Div>
      </>
    </Container>
  );
};

export default Welcome;
