import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "westside");
export const postInsuranceApi = async (Credential) =>
  await axiosInst.put(
    `/westsideportal/insertpatientInusrance?pid=${Pat_ID}`,
    Credential
  );

export const getInsuranceApi = async (Credential) =>
  await axiosInst.post(`/westsideportal/getpatientInusrance`, Credential);
