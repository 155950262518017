import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "westside");

export const upcomingAppointmentsApi = async (text) =>
  await axiosInst.get(
    `/westsideportal/upcomingAppointments?pid=${Pat_ID}&text=${text}`
  );

export const PastAppointment_Api = async () =>
  await axiosInst.get(`/westsideportal/vozopastappointment?pid=${Pat_ID}`);

export const CancelAppointment_Api = async (credential) =>
  await axiosInst.post(
    `/westsideportal/cancel/patient/appointment`,
    credential
  );

export const providersApi = async (text) =>
  await axiosInst.post(`/westsideportal/providers/list`, text);

export const facilityApi = async (find) =>
  await axiosInst.post(`/westsideportal/facility/list`, find);

export const addAppointmentApi = async (data) =>
  await axiosInst.post(`/westsideportal/add/appointment`, data);

export const EditAppointmentApi = async (data) =>
  await axiosInst.post(
    `/westsideportal/edit/appointment?appid=${data.eid}`,
    data.article
  );

export const AvailablityApi = async (data) =>
  await axiosInst.post(`/westsideportal/get/availablity`, data);

export const PortalAppAvailablityApi = async (data) =>
  await axiosInst.get(`/westsideportal/get/PortalApp/availablity`);

export const OnsiteAppReqApi = async (data) =>
  await axiosInst.get(`/westsideportal/get/Onsite/AppReq?pid=${Pat_ID}`);

// export const BillingFacilityApi = async (Data) =>
//   await axiosInst.get("/vozo/getbillingfacility");
