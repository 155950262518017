import styled from "styled-components";
export const Div = styled.div`
  display: ${(p) => p.dis && p.dis};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  min-height: ${(p) => p.minheight && p.minheight};
  max-height: ${(p) => p.maxheight && p.maxheight};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.radius && p.radius};
  outline: none;
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  border-left: ${(p) => p.borderLeft && p.borderLeft};
`;
export const Div1 = styled(Div)`
  text-align: ${(p) => (p.align ? p.align : "left")};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  background: ${(p) => p.background && p.background};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  float: ${(p) => p.float && p.float};
  cursor: ${(p) => p.cursor && p.cursor};
  align-items: ${(p) => p.alignItems && p.alignItems};
  position: ${(p) => p.position && p.position};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  width: ${(p) => p.width && p.width};
`;
export const H4 = styled.h4`
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => (p.family ? p.family : "Inter")};
  font-size: ${(p) => p.size && p.size};
  text-align: ${(p) => (p.align ? p.align : "left")};
  padding: ${(p) => p.padding && p.padding};
`;
export const CheckBoxLabel = styled.label`
  top: 0;
  left: 0;
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  margin-top: 0px;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: ${(p) => p.afrWidth && p.afrWidth};
    height: ${(p) => p.afrHeight && p.afrHeight};
    margin: ${(p) => p.afrMrgn && p.afrMrgn};
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBoxEbl = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  cursor: pointer;
  width: 42px;
  height: 23px;
  position: absolute;
  margin-right: 6px;
  &:checked + ${CheckBoxLabel} {
    background: #2c7be5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      margin: 0 0 0 21px;
      transition: 0.2s;
      margin-top: 3px;
    }
  }
`;
export const EblPatPort = styled(Div1)`
  position: relative;
  width: ${(p) => (p.patPort === 1 ? "" : "945px")};
  position: relative;
  margin: 24px 32px 0px 0px;
  display: flex;
  align-items: flex-start;
`;
