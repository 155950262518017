import { createSlice } from "@reduxjs/toolkit";

export const HealthProfileSlice = createSlice({
  name: "HealthProfile",
  initialState: {
    List: [],
    HealthGorilaActive: "",
    HealthLoading: true,
  },
  reducers: {
    GetList: (state) => {
      state.HealthLoading = true;
    },
    SetGetList: (state, action) => {
      state.List = action.payload;
      state.HealthLoading = false;
    },
  },
});

export const { GetList, SetGetList } = HealthProfileSlice.actions;

export default HealthProfileSlice.reducer;
