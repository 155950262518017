import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import CustomDatePicker from "../../StyledComponents/DatePicker/CustomDatePicker";
import Error from "../../../Modules/StyledComponents/Toastify/Error";
import calendar_icon_custom from "../../../Assets/images/calendar_icon.png";
import arrowIcon from "../../../Assets/images/arrowIcon.png";

import {
  GetPatientDetailUpdate,
  GetPatientProfileUpdate,
  GetPatientProfileRemove,
  GetEmailExist,
  Getprofileupdatestatus,
  SetEmailExist,
  GetPatSearch,
  SetPatSearch,
  SetPatientDetailUpdate,
} from "../../../StateManagement/Reducers/SettingState";

import {
  Text,
  Div,
  NormalImage,
  Button,
  Form,
  Modal,
  ModalContainer,
  ModalHeader,
  ModalBody,
  FormGroup,
  FormLabel,
  ModalFooter,
  ModalTitle,
  Image,
} from "../../StyledComponents";

import {
  Display,
  Displayerror,
  DisplayerrorText,
  Inputbox,
  CurveImg,
  ModalDialog,
  Hrline,
  DragDrop,
  FileInput,
  SelectInput,
  DropdownImage,
  DropDownDiv,
  DropdownOptions,
  TooltipComponent,
  DropDownStyle,
  AutoCompleteWrapper,
  SearchIcon,
  AutoCompleteInput,
  ErrorMessage,
} from "./styles";
import BlueCurve from "../../../Assets/images/Ellipse_2.png";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { BsXLg } from "react-icons/bs";
import UploadFile1 from "../../../Assets/images/uploadDoc.png";
import { debounce } from "../../HomeWork";
import { Imagecalendar } from "../../StyledComponents/DatePicker/styles";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import Success from "../../StyledComponents/Toastify/Success";

const EditProfile = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [revertReason, setRevertReason] = useState("");
  const [previewimg, setpreviewimg] = useState("");
  const [select, setSelect] = useState(false);
  const [upload, setUpload] = useState(true);
  const [showpop1, Setshowpop1] = useState(false);
  const [fname, setfname] = useState("");
  const [mname, setmname] = useState([]);
  const [lname, setlname] = useState([]);
  const [patimg, setpatimg] = useState([]);
  const [title, settitle] = useState([]);
  const [ss, setss] = useState([]);
  const [street, setstreet] = useState([]);
  const [city, setcity] = useState([]);
  const [state, setstate] = useState("");
  const [stateForm, setStateForm] = useState("");
  const [status1, setstatus1] = useState("");
  const [providerID, setproviderID] = useState("");
  const [ethnicity1, setethnicity1] = useState("");
  const [ethnicity2, setethnicity2] = useState("");
  const [language1, setlanguage1] = useState("");
  const [country_code, setcountry_code] = useState([]);
  const [sex1, setsex1] = useState("");
  const [relationType, setRelationType] = useState("");
  const [relation, setrelation] = useState([]);
  const [relationErr, setrelationErr] = useState(false);
  const [active, setActive] = useState("");
  const [imgfile, setimgfile] = useState("");
  const [fnameErr, setFnameErr] = useState(false);
  const [lnameErr, setLnameErr] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneCell, setPhoneCell] = useState("");
  const [phoneWork, setPhoneWork] = useState("");
  const [callEffect, setcallEffect] = useState(false);
  const [showsave, setshowsave] = useState(true);
  const [UpdateStatus, setupdateprofilestatus] = useState(0);

  const [labeltext, setlabeltext] = useState("add file");

  const [crop, SetCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 150,
    height: 150,
  });
  const [croppedImageUrl, setcroppedImageUrl] = useState("");
  const [normalImg, setNormalImg] = useState(false);
  const [jpegFile, setjpegFile] = useState("");
  const [Cropblob, setCropblob] = useState("");
  const [imageRef, setImageRef] = useState();
  const [show, setShow] = useState(false);
  const [ModalAnimat] = useState(true);
  const [uploadbtndisabled, setuploadbtndisabled] = useState(false);
  const [savepicbtndisabled, setsavepicbtndisabled] = useState(false);
  const [fromdate, setFromDate] = useState("");
  const [validDate, setValidDate] = useState("");
  const [showpicker, setShowPicker] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const [dropDown3, setDropDown3] = useState(false);
  const [dropDown4, setDropDown4] = useState(false);
  const [dropDown5, setDropDown5] = useState(false);
  const [dropDown6, setDropDown6] = useState(false);
  const [dropDown7, setDropDown7] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [cellError, setCellError] = useState(false);
  const [workError, setWorkError] = useState(false);
  const [samePhoneErr, setSamePhoneErr] = useState([]);
  const [EmailErr, setEmailErr] = useState(false);
  const [raceErr, setraceErr] = useState(false);
  const [ethniErr, setethniErr] = useState(false);
  const [stErr, setstErr] = useState(false);
  const [cityErr, setcityErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [genderErr, setgenderErr] = useState(false);
  const [countryErr, setcountryErr] = useState(false);
  const [statusErr, setstatusErr] = useState(false);
  const [dobErr, setdobErr] = useState(false);
  const [search1, setSearch1] = useState("");
  const [holdSave, setHoldSave] = useState(false);
  const [billingPatient, setBillingPatient] = useState("");
  const GenderDetails = useSelector((state) => state.Setting.PatGenderDetails);
  const MaritalStatus = useSelector((state) => state.Setting.PatMariDetails);
  const LanguageLists = useSelector((state) => state.Setting.PatLangDetails);
  const RaceDetails = useSelector((state) => state.Setting.PatRaceDetails);
  const PatDetails = useSelector((state) => state.TopNavbar.userDetails);
  const Refresh = useSelector((state) => state.Setting.Refresh);
  const constate = useSelector((state) => state.Setting.PatientState);
  const PatientRelation = useSelector((state) => state.Setting.PatientRelation);
  const PatientSearch = useSelector((state) => state.Setting.PatSearch);
  const UpdationResponse = useSelector((state) => state.Setting.updationResp);
  const inputNotRef = useRef(null);
  const maritalNotRef = useRef(null);
  const languageNotRef = useRef(null);
  const raceNotRef = useRef(null);
  const ethinicity2NotRef = useRef(null);
  const stateNotRef = useRef(null);
  const relationNoteRef = useRef(null);

  const billingPatRef = useRef(null);
  const ethnicitiesArray = [
    "Asian",
    "Black or African American",
    "Latino or Hispanic or Spanish Origin of any ethnicity/race",
    "Native Hawaiian or Other Pacific Islander",
    "Two or more ethnicities/races",
    "White",
    "Prefer not to share",
  ];
  const phoneRef = useRef(null);
  const phoneRef2 = useRef(null);
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  const File_URL = backurl + "/sites/default/documentimg/";
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "westside");
  const closePicker = () => {
    setShowPicker(false);
  };
  const handlepicker = (e) => {
    e.preventDefault();
    setShowPicker(true);
  };

  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setFromDate(moment(value).format("YYYY-MM-DD"));
      setdobErr(false);
      setValidDate(new Date(value));
    } else {
      setFromDate("");
      setValidDate("");
    }
    closePicker();
  };

  const searchChange = (data) => {
    setDropDown7(true);
    setSearch1(data);
    setrelationErr(false);

    if (data === "") {
      setBillingPatient("");
    }
  };

  const handleSelect = (option) => {
    setSearch1(option.title);
    setBillingPatient(option.pid);
    setTimeout(() => {
      setDropDown7(false);
    }, 1);
  };

  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");

    const digits = cleaned.slice(0, 10);

    let formattedNumber = "";
    for (let i = 0; i < digits.length; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += "-";
      }
      formattedNumber += digits[i];
    }
    return formattedNumber;
  };

  const keyDownExpiry = (event) => {
    const inp = document.querySelector("#date");

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };

  const closepopup = () => {
    setShow(!show);
    setSelect(false);
    setUpload(true);
    setpreviewimg("");
    setcroppedImageUrl(null);
    setImageRef();
    setimgfile("");
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const FileHandleChange = (e) => {
    setpreviewimg("");

    let iSize = e.target.files[0].size / 1024;
    iSize = Math.round(iSize * 100) / 100;

    if (iSize > 800) {
      toast(<Error msg="Please select a file less than 800kb" />, {
        containerId: "B",
        className: "error_badge",
      });

      e.target.value = "";
      return false;
    }

    var fileName = e.target.value.split("\\").pop();
    var extension = fileName.split(".").pop();
    const allowedExtensions = ["jpg", "jpeg", "png"];
    if (!allowedExtensions.includes(extension?.toLowerCase())) {
      toast(<Error msg="Please select a valid file" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    }
    setimgfile((prev) => {
      return (prev = e.target.files[0]);
    });
    if (fileName) {
      if (e.target.files && e.target.files.length > 40) {
        e.preventDefault();
        e.stopPropagation();
        setlabeltext(fileName.slice(0, 4) + "..." + extension);
      } else {
        setlabeltext(fileName);
      }
    } else {
      setlabeltext("add file");
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => setpreviewimg(reader.result));
    reader.readAsDataURL(e.target.files[0]);

    //setpreviewimg(URL.createObjectURL(e.target.files[0]));
  };

  const UploadFile = () => {
    setuploadbtndisabled(true);
    setTimeout(() => {
      setuploadbtndisabled(false);
    }, 1000);
    setSelect(true);
    setUpload(false);
    setlabeltext("add file");
    setpreviewimg("");
    setShow(true);
  };

  const CloseFile = () => {
    setSelect(false);
    setUpload(true);
    setpreviewimg("");
    setcroppedImageUrl(null);
    setImageRef();
    setimgfile("");
  };

  const UploadProfilePicture = () => {
    setsavepicbtndisabled(true);
    setTimeout(() => {
      setsavepicbtndisabled(false);
    }, 1500);

    if (imgfile === "" || imgfile === null) {
      toast(<Error msg="Please Select the File!." />, {
        containerId: "B",
        className: "error_badge",
      });

      return false;
    }

    setSelect(false);
    setUpload(true);

    const dataArray = new FormData();
    var file11 = dataURLtoFile(jpegFile, labeltext);
    dataArray.append("imgfile", file11);

    dispatch(GetPatientProfileUpdate(dataArray));
  };

  const RemoveProfilePicture = () => {
    dispatch(GetPatientProfileRemove());
    Setshowpop1(false);
    setpatimg(null);
    setimgfile(null);
  };

  const CancelPage = () => {
    Navigate("/");
  };

  const getcropimage = (e) => {
    if (imgfile === "") {
      closepopup();
    } else {
      setShow(!show);
    }
    makeClientCrop(crop);
    if (normalImg === false) {
      setNormalImg(true);
      makeClientCrop(crop);
      setpreviewimg("");
    }
  };

  const popclsbtn = () => {
    setlabeltext("add file");
    setpreviewimg("");
    setcroppedImageUrl(null);

    closepopup();
  };

  const OnSubmit = (e) => {
    e.preventDefault();
    var err = 0;

    if (fname === "") {
      setFnameErr(true);
      err = 4;
    }
    if (lname === "") {
      setLnameErr(true);
      err = 4;
    }
    if (email === "") {
      setEmailErr(true);
      err = 2;
    }

    if (street === "") {
      setstErr(true);
      err = 4;
    }
    if (city === "") {
      setcityErr(true);
      err = 4;
    }
    if (state === "") {
      setstateErr(true);
      err = 4;
    }
    if (sex1 === "") {
      setgenderErr(true);
      err = 4;
    }
    if (country_code === "") {
      setcountryErr(true);
      err = 4;
    }
    if (status1 === "") {
      setstatusErr(true);
      err = 4;
    }
    if (fromdate === "" || fromdate === null) {
      setdobErr(true);
      err = 4;
    }

    if (ethnicity1 === "") {
      setraceErr(true);
      err = 4;
    }

    if (
      (search1 !== "" && relationType === "") ||
      (search1 === "" && relationType !== "")
    ) {
      setrelationErr(true);
      err = 3;
    }

    if (ethnicity2 === "") {
      setethniErr(true);
      err = 4;
    }

    if (phoneWork.length > 0 && phoneWork.length !== 12) {
      setWorkError(true);
      phoneRef2.current.focus();
      err = 4;
    }

    if (phoneCell === "" || phoneCell.length !== 12) {
      setCellError(true);
      phoneRef.current.focus();
      err = 4;
    }

    if (err > 0) {
      return false;
    }

    let image;
    if (jpegFile) {
      const dataArray = new FormData();
      var file11 = dataURLtoFile(jpegFile, labeltext);
      dataArray.append("imgfile", file11);
      image = dataArray ? dataArray : null;
    }

    let article = {
      fname: fname,
      mname: mname,
      lname: lname,
      email: email,
      phone_cell: phoneCell,
      phone_biz: phoneWork,
      street: street,
      city: city,
      active: active,
      state: stateForm,
      postal_code: country_code,
      sex: sex1,
      contact_relationship: relationType,
      relationship_pid: search1,
      providerID: providerID,
      status: status1,
      language: language1,
      billingPatientID: billingPatient,
      race: ethnicity1,
      ethnicity: ethnicity2,
      DOB: fromdate,
    };

    dispatch(
      GetPatientDetailUpdate({
        data: article,
        image: image,
      })
    );
  };

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      setNormalImg(false);
      const ImageUrl = await getCroppedImg(imageRef, crop, "newFile.jpeg");
      setcroppedImageUrl(ImageUrl);
    } else {
      setNormalImg(true);
    }
  }

  const getCroppedImg = (sourceImage, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      sourceImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    setjpegFile(canvas.toDataURL("image/jpeg"));
    return new Promise((resolve, reject) => {
      var fileUrl;
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          setCropblob(blob);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  };

  const DropdownOptionList = [
    {
      title: "Hispanic or Latino",
      id: "1",
      Key: "Hispanic or Latino",
      value: "Hispanic or Latino",
    },
    {
      title: "Not Hispanic or Latino",
      id: "2",
      Key: "Not Hispanic or Latino",
      value: "Not Hispanic or Latino ",
    },
    {
      title: "Declined to specify",
      id: "3",
      Key: "Declined to specify",
      value: "Declined to specify ",
    },
  ];
  const Toggling = () => setDropDown((prevState) => !prevState);
  const Toggling1 = () => setDropDown1((prevState) => !prevState);
  const Toggling2 = () => setDropDown2((prevState) => !prevState);
  const Toggling3 = () => setDropDown3((prevState) => !prevState);
  const Toggling4 = () => setDropDown4((prevState) => !prevState);
  const Toggling5 = () => {
    setDropDown5((prevState) => !prevState);
    setTooltipVisible(false);
  };

  const Toggling6 = () => setDropDown6((prevState) => !prevState);

  const Toggling7 = () => setDropDown7((prevState) => !prevState);
  const setsexselect = (r) => {
    setsex1(r.title);
    setDropDown(false);
    setgenderErr(false);
  };
  const setmaritalselect = (r) => {
    setstatus1(r.title);
    setDropDown1(false);
    setstatusErr(false);
  };
  const setlanguageselect = (r) => {
    setlanguage1(r.title);
    setDropDown2(false);
  };

  const setraceselect = (r) => {
    setethnicity1(r.title);
    setDropDown3(false);
    setraceErr(false);
  };

  const setethinicityselect = (r) => {
    setethnicity2(r);
    setDropDown4(false);
    setethniErr(false);
    setethniErr(false);
  };
  const setstateselect = (s) => {
    setstate(s.title);
    setStateForm(s.option_id);
    setDropDown5(false);
    setTooltipVisible(false);
    setstateErr(false);
  };

  const relationSelect = (r) => {
    setRelationType(r.title);
    setDropDown6(false);
    setrelationErr(false);
  };

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleClick = (e) => {
    if (inputNotRef.current && !inputNotRef.current.contains(e.target)) {
      setDropDown(false);
    }
  };
  const handleClick1 = (e) => {
    if (maritalNotRef.current && !maritalNotRef.current.contains(e.target)) {
      setDropDown1(false);
    }
  };
  const handleClick2 = (e) => {
    if (languageNotRef.current && !languageNotRef.current.contains(e.target)) {
      setDropDown2(false);
    }
  };
  const handleClick3 = (e) => {
    if (raceNotRef.current && !raceNotRef.current.contains(e.target)) {
      setDropDown3(false);
    }
  };
  const handleClick4 = (e) => {
    if (
      ethinicity2NotRef.current &&
      !ethinicity2NotRef.current.contains(e.target)
    ) {
      setDropDown4(false);
    }
  };
  const handleClick5 = (e) => {
    if (stateNotRef.current && !stateNotRef.current.contains(e.target)) {
      setDropDown5(false);
    }
  };

  const handleClick6 = (e) => {
    if (
      relationNoteRef.current &&
      !relationNoteRef.current.contains(e.target)
    ) {
      setDropDown6(false);
    }
  };

  const handleClick7 = (e) => {
    if (billingPatRef.current && !billingPatRef.current.contains(e.target)) {
      setDropDown7(false);
    }
  };

  const updateprofilestatustimeout = (alertData) => {
    if (alertData !== "0" && alertData !== 0) {
      setupdateprofilestatus(0);
      dispatch(Getprofileupdatestatus("profile"));
    }
  };

  const handleKeyPress = (e) => {
    const regex = /^[a-zA-Z]+$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleCellChange = (event) => {
    setcallEffect(!callEffect);

    const input = event.target.value;
    const formatted = formatPhoneNumber(input);
    setPhoneCell(formatted);
    setCellError(false);
    samePhoneErr[0] = false;
  };

  const handleWorkChange = (e, index) => {
    setcallEffect(!callEffect);

    const input = e.target.value;
    const formatted = formatPhoneNumber(input);

    setWorkError(false);
    samePhoneErr[1] = false;
    setPhoneWork(formatted);
  };

  useEffect(() => {
    if (Refresh === 2) {
      Setshowpop1(false);
      setpreviewimg(null);
    }
  }, [Refresh]);
  useEffect(() => {
    if (UpdationResponse === "Mobile Phone number already used") {
      setCellError(true);
    }
    if (UpdationResponse === " Work Phone number already used") {
      setWorkError(true);
    }
    if (UpdationResponse === "Email already used") {
      setEmailErr(true);
    }
    if (UpdationResponse === "success") {
      toast(<Success msg={"Details Sent to the Review Succeedly."} />, {
        containerId: "A",
      });
    } else if (UpdationResponse === "failure") {
      toast(<Error msg={"Please do some changes."} />, {
        containerId: "A",
      });
    }
    setTimeout(() => {
      dispatch(SetPatientDetailUpdate());
    }, 1000);
  }, [UpdationResponse]);

  const handleChange = (mailcheck) => {
    dispatch(GetEmailExist({ type: mailcheck }));
  };

  const handleEmailChange = (e, index) => {
    const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (mailRegex.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
    setEmail(e.target.value);
  };
  useEffect(() => {
    if (Object.keys(PatDetails).length > 0) {
      setfname(PatDetails.fname);
      setmname(PatDetails.mname);
      setlname(PatDetails.lname);
      setActive(PatDetails.active);
      setPhoneCell(PatDetails.phone_cell);
      setPhoneWork(PatDetails.phone_biz);
      setstreet(PatDetails.street);
      setcity(PatDetails.city);
      setstate(PatDetails.state);
      setStateForm(PatDetails.state);
      setRelationType(PatDetails.contact_relationship);
      setSearch1(
        PatDetails.relationship_pid === null ? "" : PatDetails.relation_name
      );
      //want to check above
      if (setstate) {
        constate.forEach((s) => {
          if (
            s.option_id === PatDetails.state ||
            s.title === PatDetails.state
          ) {
            setstate(s.title);
          }
        });
      }
      setcountry_code(PatDetails.postal_code);
      setFromDate(PatDetails.DOB);
      setsex1(PatDetails.sex);
      setethnicity1(PatDetails.race);
      setethnicity2(PatDetails.ethnicity);
      setlanguage1(PatDetails.language);
      setproviderID(PatDetails.providerID);
      setstatus1(PatDetails.status);
      setEmail(PatDetails.email);
      // if (setstatus1) {
      //   MaritalStatus.forEach((s) => {
      //     if (s.option_id === PatDetails.status) {
      //       setstatus1(s.title);
      //     }
      //   });
      // }
      setpatimg(PatDetails.img_url);
      setupdateprofilestatus(PatDetails.update_profile_status);
      setRevertReason(PatDetails.revert_reason);

      setshowsave(PatDetails.showsave);

      dispatch(SetEmailExist(""));
      if (
        PatDetails.update_profile_status !== "0" &&
        PatDetails.update_profile_status !== 0
      ) {
        setTimeout(
          () => updateprofilestatustimeout(PatDetails.update_profile_status),
          5000
        );
      }
    }
  }, [PatDetails, constate]);

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (search1 !== "") {
          let articles =
            "limit=10&fname=" + search1 + "&pid=" + Pat_ID + "&mode=insurance";
          dispatch(GetPatSearch(articles));
        }
      }
    };
    handlechange();

    if (search1 === "") {
      dispatch(SetPatSearch());
      setDropDown7(false);
    }
    return () => {
      isCancelled = true;
    };
  }, [search1]);

  useEffect(() => {
    document.addEventListener("mouseup", handleClick);
    document.addEventListener("mouseup", handleClick1);
    document.addEventListener("mouseup", handleClick2);
    document.addEventListener("mouseup", handleClick3);
    document.addEventListener("mouseup", handleClick4);
    document.addEventListener("mouseup", handleClick5);
    document.addEventListener("mouseup", handleClick6);
    document.addEventListener("mouseup", handleClick7);
    return () => {
      document.removeEventListener("mouseup", handleClick);
      document.removeEventListener("mouseup", handleClick1);
      document.removeEventListener("mouseup", handleClick2);
      document.removeEventListener("mouseup", handleClick3);
      document.removeEventListener("mouseup", handleClick4);
      document.removeEventListener("mouseup", handleClick5);
      document.removeEventListener("mouseup", handleClick6);
      document.removeEventListener("mouseup", handleClick7);
    };
  }, []);
  return (
    <>
      <Display display="flex" alignItems="center" mb="24px">
        {PatDetails.img_url === null ? (
          <Div
            border="1.5px solid #e9eef9"
            position="relative"
            height="70px"
            width="70px"
            minWidth="70px"
            minHeight="70px"
            borderRadius="50%"
            marginRight="20px"
            padding="4px"
          >
            <CurveImg
              position="absolute"
              top="-0.5px"
              left="38px"
              width="30px"
              height="30px"
              src={BlueCurve}
            />
            <Div
              background="#A8AEBE"
              height="100%"
              width="100%"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="#FFFFFF"
              fontSize="24px"
              fontWeight="600"
            >
              {PatDetails.initials}
            </Div>
          </Div>
        ) : (
          <Div
            border="1.5px solid #e9eef9"
            position="relative"
            height="70px"
            width="70px"
            minWidth="70px"
            minHeight="70px"
            borderRadius="50%"
            marginRight="20px"
            padding="4px"
          >
            <CurveImg
              position="absolute"
              top="-0.5px"
              left="38px"
              width="30px"
              height="30px"
              src={BlueCurve}
            />
            <NormalImage
              loading="lazy"
              height="100%"
              width="100%"
              borderRadius="50%"
              src={File_URL + patimg}
            />
          </Div>
        )}
        <Display display="inline">
          <Text
            fontSize="24px"
            fontWeight="700"
            color="#000000"
            mb="12px"
            lineHeight="33px"
            textAlign="left"
          >
            {fname} {lname}
          </Text>
          <Text
            fontFamily="Inter"
            fontWeight="400"
            fontSize="14px"
            color="#000000"
            lineHeight="23px"
            textAlign="left"
            mb="0px"
          >
            Set up your Patient Portal
          </Text>
        </Display>
      </Display>

      {UpdateStatus === "1" || UpdateStatus === 1 ? (
        <Displayerror
          display="flex"
          background="#77E0B5"
          width="100%"
          alignItems="center"
          pt="10px"
          pb="10px"
          justifyContent="center"
          color="#FFFFFF"
          mb="24px"
        >
          Profile updated successfully
        </Displayerror>
      ) : (
        <>
          {UpdateStatus === "2" || UpdateStatus === 2 ? (
            <Displayerror
              background="#FF7676"
              width="100%"
              alignItems="center"
              pt="10px"
              pb="10px"
              justifyContent="center"
              mb="24px"
            >
              <DisplayerrorText
                fontFamily="'Inter'"
                fontWeight="600"
                fontSize="16px"
                lineHeight="19px"
                textAlign="center"
                color="#ffffff"
              >
                Profile updated was Revert
              </DisplayerrorText>
              <DisplayerrorText
                fontFamily="Work Sans"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center"
                color="#ffffff"
                mt="4px"
              >
                {revertReason}
              </DisplayerrorText>
            </Displayerror>
          ) : (
            <></>
          )}
        </>
      )}
      <Display display="flex" mb="24px">
        {croppedImageUrl ? (
          <Div
            height="60px"
            width="60px"
            marginRight="20px"
            marginLeft="3px"
            border="2px solid #D3D3D3"
            borderRadius="50%"
          >
            <NormalImage
              loading="lazy"
              height="100%"
              width="100%"
              borderRadius="50%"
              src={croppedImageUrl}
            />
          </Div>
        ) : patimg === null ? (
          <Div
            background="#A8AEBE"
            height="60px"
            width="60px"
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginRight="20px"
            border="2px solid #D3D3D3"
            color="#FFFFFF"
            fontSize="24px"
            fontWeight="600"
          >
            {PatDetails.initials}
          </Div>
        ) : (
          <Div
            height="60px"
            width="60px"
            marginRight="20px"
            marginLeft="3px"
            border="2px solid #D3D3D3"
            borderRadius="50%"
          >
            <NormalImage
              loading="lazy"
              height="100%"
              width="100%"
              borderRadius="50%"
              src={File_URL + patimg}
            />
          </Div>
        )}
        <Div display="flex" alignItems="center" justifyContent="center">
          {select && (
            <>
              <Display>
                {/* {patimg !== null && (
                  <Button
                    padding="12px 18px"
                    background="#e7e7e9"
                    borderRadius="8px"
                    fontFamily="'Inter',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    color="#000000"
                    marginBottom="11px"
                    marginTop="5px"
                    onClick={() => {
                      showpop1(true);
                    }}
                  >
                    Delete
                  </Button>
                )} 
                <br />
                <Inputbox
                  fontFamily="'Inter',sans-serif"
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="17px"
                  color="#0d0c22"
                  mb="12px"
                  type="file"
                  accept=".png,.jpg, .jpeg"
                  max-size="800000"
                  onChange={(e) => FileHandleChange(e)}
                />
                <Div
                  fontSize="14px"
                  color="#A3A3A3"
                  lineHeight="17px"
                  marginBottom="16px"
                >
                  JPG,JPEG or PNG. Max size of 800KB
                  </Div> */}
                <Display display="flex">
                  <Button
                    padding="12px 20px"
                    background="#2C7BE5"
                    hoverBackColor="#005FB2"
                    borderRadius="8px"
                    fontWeight="500"
                    fontSize="16px"
                    color=" #FFFFFF"
                    lineHeight="16px"
                    marginRight="16px"
                    className="save_active"
                    disabled={savepicbtndisabled}
                    onClick={UploadProfilePicture}
                  >
                    Save Picture
                  </Button>
                  <Button
                    color="#2C7BE5"
                    background="#FFF"
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    border="1px solid #2C7BE5"
                    borderRadius="4px"
                    fontFamily="'Inter',sans-serif"
                    padding="12px 16px"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="17px"
                    onClick={CloseFile}
                    disabled={uploadbtndisabled}
                  >
                    Cancel
                  </Button>
                </Display>
              </Display>
            </>
          )}

          {upload && (
            <>
              <Button
                padding="12px 20px"
                borderRadius="8px"
                background="#2C7BE5"
                hoverBackColor="#005FB2"
                fontWeight="500"
                lineHeight="16px"
                fontSize="16px"
                color="#FFFFFF"
                marginRight="23px"
                onClick={UploadFile}
                className="save_active"
                disabled={uploadbtndisabled}
              >
                Upload New Picture
              </Button>
              {patimg !== null && (
                <Button
                  padding="12px 18px"
                  background="#e7e7e9"
                  borderRadius="8px"
                  fontFamily="'Inter',sans-serif"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="17px"
                  color="#000000"
                  onClick={() => {
                    Setshowpop1(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </>
          )}
        </Div>
      </Display>

      <Form>
        <Display display="flex" mb="20px">
          <FormGroup display="grid" width="20%" marginRight="23px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              First Name
            </FormLabel>

            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              placeholder="Enter First Name"
              value={fname}
              onChange={(e) => {
                setfname(e.target.value);
                setFnameErr(false);
              }}
              onKeyPress={handleKeyPress}
              id="edPf_inp-fnm"
            />
            {fnameErr && (
              <Text
                textAlign="left"
                color="red"
                fontSize="10px"
                fontWeight="400"
                mb="0px"
                mt="0px"
              >
                Enter the First Name
              </Text>
            )}
          </FormGroup>

          <FormGroup display="grid" width="20%" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Middle Name
            </FormLabel>
            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              placeholder="Enter Middle Name"
              value={mname}
              onChange={(e) => setmname(e.target.value)}
              onKeyPress={handleKeyPress}
              id="edPf_inp-mdnm"
            />
          </FormGroup>

          <FormGroup display="grid" width="20%">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Last Name
            </FormLabel>
            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              value={lname}
              onChange={(e) => {
                setlname(e.target.value);
                setLnameErr(false);
              }}
              onKeyPress={handleKeyPress}
              id="edPf_inp-lstnm"
            />
            {lnameErr ? (
              <Text
                textAlign="Left"
                color="red"
                fontSize="10px"
                fontWeight="400"
                mb="0px"
                mt="0px"
              >
                Enter the Last Name
              </Text>
            ) : (
              ""
            )}
          </FormGroup>
        </Display>

        <Hrline ml="-40px" mb="23px" />
        <Display display="flex" mb="20px">
          <FormGroup display="grid" width="40%" marginRight="23px">
            <Text
              fontWeight="500"
              fontSize="16px"
              marginBottom="8px"
              textAlign="left"
              color="#2D3748"
              fontFamily="'Inter',san-serif"
            >
              Mobile Phone Number
            </Text>
            <Div>
              <Inputbox
                width="100%"
                height="32px"
                background="#ffffff"
                border="1px solid #cbd5e0"
                borderRadius="4px"
                mr="12px"
                paddingLeft="12px"
                name="phone"
                value={phoneCell}
                onChange={(e) => {
                  handleCellChange(e);
                }}
                style={
                  cellError || (samePhoneErr[0] && samePhoneErr[0] === "true")
                    ? {
                        border: "1px solid red",
                        borderRadius: "5px",
                      }
                    : {}
                }
              />
            </Div>
            {samePhoneErr[0] && samePhoneErr[0] === "true" && (
              <ErrorMessage>This number already used</ErrorMessage>
            )}
            {cellError && (
              <ErrorMessage>
                {UpdationResponse !== undefined
                  ? UpdationResponse
                  : UpdationResponse === undefined &&
                    "Enter valid phone number"}
              </ErrorMessage>
            )}
          </FormGroup>
        </Display>
        <Display display="flex" mb="20px">
          <FormGroup display="grid" width="40%" marginRight="23px">
            <Text
              fontWeight="500"
              fontSize="16px"
              marginBottom="8px"
              textAlign="left"
              color="#2D3748"
              fontFamily="'Inter',san-serif"
            >
              Work Phone Number
            </Text>
            <Div>
              <Inputbox
                width="100%"
                height="32px"
                background="#ffffff"
                border="1px solid #cbd5e0"
                borderRadius="4px"
                mr="12px"
                paddingLeft="12px"
                name="phone"
                value={phoneWork}
                ref={phoneRef2}
                onChange={(e) => {
                  handleWorkChange(e);
                }}
                // id={`edPf_inp-phNm${ind}`}
                style={
                  workError || (samePhoneErr[1] && samePhoneErr[1] === "true")
                    ? {
                        border: "1px solid red",
                        borderRadius: "5px",
                      }
                    : {}
                }
              />
            </Div>
            {workError && (
              <ErrorMessage>
                {UpdationResponse !== undefined
                  ? UpdationResponse
                  : "Enter valid phone number"}
              </ErrorMessage>
            )}

            {samePhoneErr[1] && samePhoneErr[1] === "true" && (
              <ErrorMessage>This number already used</ErrorMessage>
            )}
          </FormGroup>
        </Display>
        <Hrline ml="-40px" mt="0px" mb="16px" />
        <Display display="flex" mb="20px">
          <FormGroup display="grid" width="40%" marginRight="23px">
            <Text
              fontWeight="500"
              fontSize="16px"
              marginBottom="8px"
              textAlign="left"
              color="#2D3748"
              fontFamily="'Inter',san-serif"
            >
              Email
            </Text>
            <Div>
              <Inputbox
                width="100%"
                height="32px"
                background="#ffffff"
                border="1px solid #cbd5e0"
                borderRadius="4px"
                mr="12px"
                paddingLeft="12px"
                name="phone"
                value={email}
                style={
                  EmailErr
                    ? {
                        border: "1px solid red",
                        borderRadius: "4px",
                      }
                    : { borderRadius: "4px" }
                }
                onChange={(e) => handleEmailChange(e)}
                onPaste={(e) => handleEmailChange(e)}
                // id={`edPf_inp-phNm${ind}`}
              />
            </Div>
            {EmailErr && (
              <ErrorMessage>
                {UpdationResponse !== undefined
                  ? UpdationResponse
                  : " Please Select Valid Email"}
              </ErrorMessage>
            )}
          </FormGroup>
        </Display>
        <Display display="flex" mb="20px">
          <FormGroup display="grid" width="30%" marginRight="23px">
            <div>
              <FormLabel className="m-b-18">
                Patient Responsible for billing
              </FormLabel>
              <Div ref={billingPatRef} onClick={Toggling7}>
                <AutoCompleteWrapper
                  className="for_autoComplete_bi pl ad-contact-autocomplete"
                  marginTop="0px"
                >
                  <SearchIcon />
                  <AutoCompleteInput
                    // ref={relationNoteRef}
                    id="crt_bil-prof-icd9"
                    className="search_width w-100 h-34 pat-info-select"
                    placeholder="Search Patient"
                    onChange={(e) => {
                      searchChange(e.target.value);
                      // handleSearch(e.target.value);
                    }}
                    value={search1}
                    style={
                      relationErr
                        ? {
                            border: "1px solid red",
                            borderRadius: "5px",
                          }
                        : {}
                    }
                  />
                  {dropDown7 ? (
                    PatientSearch &&
                    PatientSearch !== undefined &&
                    PatientSearch.length > 0 ? (
                      <DropDownDiv
                        width="100%"
                        background="#FFFFFF"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        maxHeight="225px"
                        overflowY="auto"
                        top="36px"
                        //  ref={billingPatRef}
                      >
                        {PatientSearch.map((r, i) => {
                          return (
                            <DropdownOptions
                              fontSize="14px"
                              key={i}
                              value={r.title}
                              padding="10px 12px 8px 12px"
                              onClick={() => handleSelect(r)}
                            >
                              {r.title}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    ) : (
                      <DropDownDiv
                        width="100%"
                        background="#FFFFFF"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        maxHeight="225px"
                        overflowY="auto"
                        top="36px"
                      >
                        No Result Found
                      </DropDownDiv>
                    )
                  ) : (
                    PatientSearch === undefined && ""
                  )}
                </AutoCompleteWrapper>
              </Div>
              {relationErr && (
                <ErrorMessage className="errormessage">
                  Please Select both options
                </ErrorMessage>
              )}
            </div>
          </FormGroup>

          <FormGroup display="grid" width="30%" marginRight="23px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="0px"
              textAlign="left"
            >
              Relationship Type
            </FormLabel>
            <DropDownStyle marginTop="0px" ref={relationNoteRef}>
              <Div onClick={Toggling6}>
                <SelectInput
                  // width="220px"
                  height="34px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="sex"
                  fontFamily="Inter"
                  fontWeight="400"
                  letterSpacing="0.0025em"
                  color="#747474"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  padding="0px 12px"
                  value={relationType}
                  style={
                    relationErr
                      ? {
                          border: "1px solid red",
                          borderRadius: "4px",
                        }
                      : { borderRadius: "4px" }
                  }
                  id="my_pf-pat_sx"
                >
                  {relationType || "Select Anyone"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>

              {dropDown6 && PatientRelation.length > 0 ? (
                <DropDownDiv
                  width="100%"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                >
                  {PatientRelation.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={i}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => relationSelect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
            {relationErr && (
              <ErrorMessage>Please Select both options</ErrorMessage>
            )}
          </FormGroup>
        </Display>
        <Hrline ml="-40px" mt="0px" mb="16px" />

        <Text
          fontFamily="'Inter',san-serif"
          fontWeight="500"
          fontSize="16px"
          marginBottom="8px"
          textAlign="left"
        >
          Address
        </Text>

        {/* <Div
          fontFamily="'Inter',san-serif"
          fontSize="12px"
          lineHeight="15px"
          color="#718096"
          marginBottom="12px"
        >
          If you want to send this client email reminders or billing documents,
          and to grant them Client Portal access, add their email address.
        </Div> */}

        <FormGroup display="grid" marginBottom="12px">
          <FormLabel
            fontFamily="'Inter',san-serif"
            fontSize="14px"
            lineHeight="17px"
            color="#2d3748"
            marginBottom="8px"
            textAlign="left"
          >
            Street
          </FormLabel>
          <Inputbox
            width="376px"
            height="32px"
            background="#ffffff"
            border="1px solid #cbd5e0"
            borderRadius="4px"
            paddingLeft="12px"
            value={street}
            onChange={(e) => {
              setstreet(e.target.value);
              setstErr(false);
            }}
            style={
              stErr
                ? {
                    border: "1px solid red",
                    borderRadius: "5px",
                  }
                : {}
            }
          />

          {stErr && <ErrorMessage>Please Enter Valid Street</ErrorMessage>}
        </FormGroup>
        <Display display="flex">
          <FormGroup display="flex" flexDirection="column" marginRight="5px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              City
            </FormLabel>
            <Inputbox
              paddingLeft="12px"
              width="160px"
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              value={city}
              onChange={(e) => {
                setcityErr(false);
                let a = /[0-9 ]/i.test(e.target.value);
                if ((isNaN(e.target.value) && !a) || e.target.value === "") {
                  setcity(e.target.value);
                }
              }}
              style={
                cityErr
                  ? {
                      border: "1px solid red",
                      borderRadius: "5px",
                    }
                  : {}
              }
              id="my_pf-inp_cty"
            />

            {cityErr && <ErrorMessage>Please Enter Valid City</ErrorMessage>}
          </FormGroup>

          <FormGroup display="flex" flexDirection="column" marginRight="5px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="0px"
              textAlign="left"
            >
              State
            </FormLabel>

            <DropDownStyle marginTop="0px" ref={stateNotRef}>
              <Div
                onClick={Toggling5}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <SelectInput
                  width="100px"
                  height="32px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="sex"
                  fontFamily="Inter"
                  fontWeight="400"
                  letterSpacing="0.0025em"
                  color="#747474"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  padding="0px 12px"
                  value={state}
                  id="my_pf-inp_sta"
                  style={
                    stateErr
                      ? {
                          border: "1px solid red",
                          borderRadius: "5px",
                        }
                      : {}
                  }
                  onChange={(e) => {
                    setstate(e.target.value);
                    setstateErr(false);
                  }}
                >
                  <Div
                    width="72px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    color="#747474"
                  >
                    {state || "Select"}
                  </Div>
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="6px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>

              {state && tooltipVisible ? (
                <TooltipComponent
                  marginTop="4px"
                  background="#f5f5f5"
                  color="rgb(45,55,72)"
                  height="auto"
                  width="auto"
                  fontSize="12px"
                  fontWeight="400"
                  border="1px solid rgba(0,0,0,0.125)"
                  borderRadius="4px"
                  padding="2px 10px 3px 10px"
                  backgroundClip="border-box"
                >
                  {state ? state : "Select"}
                </TooltipComponent>
              ) : null}
              {dropDown5 && constate.length > 0 ? (
                <DropDownDiv
                  width="100px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                  bottom="31px"
                >
                  {state !== "" && state !== null
                    ? constate.map((s, i) =>
                        s.option_id.toLowerCase() === state.toLowerCase() ? (
                          <DropdownOptions
                            padding="2px 1px 2px 1px"
                            fontSize="12px"
                            key={`3g${i}`}
                            value={s.option_id}
                            id={`adCon_sta${i}`}
                            onClick={() => setstateselect(s)}
                          >
                            {s.title}
                          </DropdownOptions>
                        ) : (
                          <DropdownOptions
                            padding="2px 1px 2px 1px"
                            fontSize="12px"
                            key={`3g${i}`}
                            value={s.title}
                            id={`adCon_sta${i}`}
                            onClick={() => setstateselect(s)}
                          >
                            {s.title}
                          </DropdownOptions>
                        )
                      )
                    : constate.map((s, i) => (
                        <DropdownOptions
                          padding="2px 1px 2px 1px"
                          fontSize="12px"
                          key={`3g1${i}`}
                          value={s.title}
                          id={`adCon_sta${i}`}
                          onClick={() => setstateselect(s)}
                        >
                          {s.title}
                        </DropdownOptions>
                      ))}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
            {stateErr && <ErrorMessage>Please Select Any State</ErrorMessage>}
          </FormGroup>

          <FormGroup display="flex" flexDirection="column">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Zip
            </FormLabel>
            <Inputbox
              width="100px"
              paddingLeft="12px"
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              value={country_code}
              onChange={(e) => {
                if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
                  setcountry_code(e.target.value);
                  setcountryErr(false);
                }
              }}
              id="my_pf-inp_zp"
              style={
                countryErr
                  ? {
                      border: "1px solid red",
                      borderRadius: "5px",
                    }
                  : {}
              }
            />
            {countryErr && (
              <ErrorMessage>Please Select Country Code</ErrorMessage>
            )}
          </FormGroup>
        </Display>

        <Hrline ml="-40px" mt="16px" mb="16px" />
        <Text
          fontWeight="500"
          fontSize="16px"
          marginBottom="12px"
          textAlign="left"
          color="#2D3748"
        >
          About Patient
        </Text>
        <Display display="flex" mb="20px">
          <FormGroup display="grid">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Date of Birth
            </FormLabel>
            <Div
              display="flex"
              position="relative"
              width="220px"
              marginRight="24px"
              alignItems="center"
            >
              <CustomDatePicker
                id="CustomDatePicker"
                width="220px"
                height="32px"
                backgroundColour="#f7f7f7"
                border={
                  dobErr ? "1px solid red" : "1px solid rgba(34,36,38,.15)"
                }
                placeholder="DD-MM-YYYY"
                value={
                  fromdate !== "" && fromdate
                    ? moment(fromdate).format("DD-MM-YYYY")
                    : ""
                } //used in application
                onChange={(e) => {
                  let d = moment(e.target.value, "YYYY-MM-DD", true).isValid();
                  setFromDate(e.target.value);
                  if (e.target.value === "") {
                    setValidDate("");
                  } else if (d) {
                    setValidDate(e.target.value);
                    setdobErr(false);
                  }
                }}
                onClick={(e) => handlepicker(e)}
                onKeyPress={(event) => {
                  if (
                    !/[0-9]/.test(event.key) ||
                    event.target.value.length > 9
                  ) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(e) => keyDownExpiry(e)}
                onResultSelect={dateSelect} // state updation for picking calendar dates
                showPicker={showpicker}
                validDate={validDate} // value in date object for calendar manipulation
                closePicker={closePicker}
              ></CustomDatePicker>
              <Imagecalendar
                id="CustomDatePicker"
                onClick={(e) => handlepicker(e)}
                src={calendar_icon_custom}
              ></Imagecalendar>
            </Div>

            {dobErr && (
              <ErrorMessage position="absolute" top="50px">
                Please select DOB
              </ErrorMessage>
            )}
          </FormGroup>
          <FormGroup display="grid">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="0px"
              textAlign="left"
            >
              Sex
            </FormLabel>
            <DropDownStyle marginTop="0px" ref={inputNotRef}>
              <Div onClick={Toggling}>
                <SelectInput
                  width="220px"
                  height="30px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="sex"
                  fontFamily="Inter"
                  fontWeight="400"
                  letterSpacing="0.0025em"
                  color="#747474"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={sex1}
                  id="my_pf-pat_sx"
                  style={
                    genderErr
                      ? {
                          border: "1px solid red",
                          borderRadius: "5px",
                        }
                      : {}
                  }
                >
                  {sex1 || "Select Gender"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>

              {dropDown && GenderDetails.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                >
                  {GenderDetails.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={i}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => setsexselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
            {genderErr && (
              <ErrorMessage position="absolute" top="50px">
                Please select Gender
              </ErrorMessage>
            )}
          </FormGroup>
          <FormGroup display="grid">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="0px"
              textAlign="left"
            >
              Marital Status
            </FormLabel>
            <DropDownStyle marginTop="0px" ref={maritalNotRef}>
              <Div onClick={Toggling1}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#FFFFFF"
                  name="marital"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={status1}
                  id="mypf_pat-mri_sta"
                  style={
                    statusErr
                      ? {
                          border: "1px solid red",
                          borderRadius: "5px",
                        }
                      : {}
                  }
                >
                  {status1 || "Select Marital"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>

              {dropDown1 && MaritalStatus.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                >
                  {MaritalStatus.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={i}
                        value={r.title}
                        padding="7px 12px 7px 12px"
                        onClick={() => setmaritalselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
            {statusErr && (
              <ErrorMessage position="absolute" top="50px">
                Please select Marital Status
              </ErrorMessage>
            )}
          </FormGroup>
        </Display>
        <Display display="flex" mb="20px">
          <FormGroup display="grid">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Language
            </FormLabel>
            <DropDownStyle marginTop="0px" ref={languageNotRef}>
              <Div onClick={Toggling2}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#ffffff"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  name="language"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={language1}
                  id="mypf_pat-lng"
                >
                  {language1 ? language1 : "Select Language"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>
              {dropDown2 && LanguageLists.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                  bottom="31px"
                >
                  {LanguageLists.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={`${i}lang`}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => setlanguageselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
          </FormGroup>
          <FormGroup display="grid">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Race
            </FormLabel>
            <DropDownStyle marginTop="0px" ref={raceNotRef}>
              <Div onClick={Toggling3}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#ffffff"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  name="race"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={ethnicity1}
                  id="mypf_pat-race"
                  style={
                    raceErr
                      ? {
                          border: "1px solid red",
                          borderRadius: "5px",
                        }
                      : {}
                  }
                >
                  {ethnicity1 || "Select Race"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>

              {dropDown3 && RaceDetails.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                  bottom="31px"
                >
                  {RaceDetails.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={`${i}ethnicity`}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => setraceselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
            {raceErr && (
              <ErrorMessage position="absolute" top="55px">
                Please Select Any
              </ErrorMessage>
            )}
          </FormGroup>

          <FormGroup display="grid">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Ethnicity
            </FormLabel>
            <DropDownStyle marginTop="0px" ref={ethinicity2NotRef}>
              <Div onClick={Toggling4}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  overFlow="hidden"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#ffffff"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  name="ethnicity"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={ethnicity2}
                  id="mypf_pat-ethn"
                  style={
                    ethniErr
                      ? {
                          border: "1px solid red",
                          borderRadius: "5px",
                        }
                      : {}
                  }
                >
                  {ethnicity2 || "Select Ethnicity"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>

              {dropDown4 && DropdownOptionList.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflow="auto"
                  bottom="31px"
                >
                  {ethnicitiesArray.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={`${i}ethnicity`}
                        value={r}
                        padding="10px 12px 8px 12px"
                        onClick={() => setethinicityselect(r)}
                      >
                        {r}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
            {ethniErr && (
              <ErrorMessage position="absolute" top="55px">
                Please Select Any
              </ErrorMessage>
            )}
          </FormGroup>
        </Display>

        <Hrline ml="-40px" mt="0px" mb="20px" />
        <Display display="flex" pb="20px">
          <Button
            padding="8px 16px"
            height="32px"
            border=" 1px solid #2C7BE5"
            background=" #FFF"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            color="#2C7BE5"
            marginRight="20px"
            className="close_active"
            onClick={CancelPage}
          >
            Cancel
          </Button>
          <Button
            padding="7px 32px 9px 32px"
            height="32px"
            background="#2C7BE5"
            hoverBackColor="#005FB2"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            disabled={(showsave || holdSave) && true}
            color="#ffffff"
            border="none"
            className="save_active"
            title={holdSave ? "Please Wait while validating your mail" : ""}
            onClick={OnSubmit}
            id="edPr_btn-sv"
          >
            {showsave ? "Waiting For Review" : "Save"}
          </Button>
        </Display>
      </Form>

      <Modal width="100%" height="100%" show={showpop1}>
        <ModalContainer justifyContent="center" alignItems="flex-start">
          <ModalDialog width="377px">
            <ModalHeader
              padding="20px 20px"
              borderLess
              justifyContent="space-between"
            >
              <ModalTitle
                lineHight="16px"
                fontSize="18px"
                fontWeight="400"
                color="#2E2C34"
                fontFamily="'Inter',Sans-Serif"
              >
                Deleting avatar. Continue?
              </ModalTitle>
            </ModalHeader>
            <ModalBody
              padding="0px 20px 20px 20px"
              lineHight="16px"
              fontSize="16px"
              fontWeight="400"
              color="#6A6A6A"
              fontFamily="'Inter',Sans-Serif"
            >
              Deleting avatar. Continue?
            </ModalBody>
            <ModalFooter padding="0px 20px 20px 20px" borderTop="none">
              <Div display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  padding="8px 16px"
                  height="32px"
                  background="#FFFFFF"
                  border="1px solid #2C7BE5"
                  borderRadius="4px"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  color="#2C7BE5"
                  marginRight="20px"
                  fontSize="16px"
                  className="close_active"
                  onClick={() => {
                    Setshowpop1(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  padding="7px 32px 9px 32px"
                  height="32px"
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  borderRadius="4px"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  color="#FFFFFF"
                  border="none"
                  fontSize="16px"
                  className="save_active"
                  onClick={RemoveProfilePicture}
                >
                  Ok
                </Button>
              </Div>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>

      <Modal width="100%" height="100%" show={show}>
        <ModalContainer justifyContent="center" alignItems="center">
          <ModalDialog animation={ModalAnimat} padding="32px" width="414px">
            <Div
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="20px"
                fontWeight="600"
                color="#0a1629"
              >
                Upload Documents
              </ModalTitle>

              <BsXLg
                style={{
                  color: "#407BFF",
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={closepopup}
              />
            </Div>

            <DragDrop>
              {!previewimg && (
                <Text
                  fontSize="16px"
                  lineHeight=" 22px"
                  color="#263446"
                  marginBottom="20px"
                  mt="20px"
                  id="dropContainer"
                  style={{ border: "1px solid black;height:100px" }}
                >
                  Drag &amp; Drop
                </Text>
              )}
              {previewimg ? (
                <ReactCrop
                  src={previewimg}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={(imageRef) => setImageRef(imageRef)}
                  onComplete={(crop) => makeClientCrop(crop)}
                  onChange={(crop) => SetCrop(crop)}
                  crossorigin="anonymous"
                />
              ) : (
                <Image
                  loading="lazy"
                  width="45px"
                  margin="0px auto 0px auto"
                  src={UploadFile1}
                />
              )}
              {!previewimg && (
                <>
                  <FileInput
                    type="file"
                    id="fileInput"
                    multiple
                    onChange={(e) => FileHandleChange(e)}
                    accept=".png, .jpg, .jpeg"
                  />{" "}
                  <Text
                    fontSize="12px"
                    color="#7d8592"
                    marginBottom="20px"
                    mt="30px"
                  >
                    JPG,PNG or JPEG Smaller than 2 MB or browse for a file to
                    upload
                  </Text>
                </>
              )}
              {/* {!croppedImageUrl && previewimg && (
                  <Text
                    fontSize="12px"
                    color="#7d8592"
                    marginBottom="20px"
                    mt="20px"
                  >
                    {labeltext}
                  </Text>
                )} */}
            </DragDrop>

            <ModalFooter marginTop="20px" borderTop="none">
              <Div display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  padding="8px 16px"
                  height="32px"
                  borderRadius="4px"
                  color=" #2C7BE5"
                  border=" 1px solid #2C7BE5"
                  background=" #FFF"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  marginRight="20px"
                  fontSize="16px"
                  className="close_active"
                  onClick={popclsbtn}
                >
                  Cancel
                </Button>
                <Button
                  padding="7px 32px 9px 32px"
                  height="32px"
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  borderRadius="4px"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  color="#FFFFFF"
                  border="none"
                  fontSize="16px"
                  className="save_active"
                  onClick={getcropimage}
                >
                  Ok
                </Button>
              </Div>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default EditProfile;
