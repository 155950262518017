import { takeLatest } from "redux-saga/effects";

import {
  GetEmailRes,
  GetReEmailRes,
  GetPassRes,
  GetSignUp,
  GetLoginStatus,
  GetProvStatus,
} from "../../StateManagement/Reducers/PreLoginState";

import {
  LoginEmailCheckApi,
  LoginPasswordSetApi,
  PasswordResetEmailCheckApi,
  SignUpApi,
  LogChecking,
  ProvStatusCheck,
} from "./PreLoginWorkers";

export function* LoginEmailCheck() {
  yield takeLatest(GetEmailRes.type, LoginEmailCheckApi);
}

export function* LoginPassWordSet() {
  yield takeLatest(GetPassRes.type, LoginPasswordSetApi);
}

export function* PasswordResetEmailCheck() {
  yield takeLatest(GetReEmailRes.type, PasswordResetEmailCheckApi);
}

export function* SignUp() {
  yield takeLatest(GetSignUp.type, SignUpApi);
}

export function* VzPortLogCheck() {
  yield takeLatest(GetLoginStatus.type, LogChecking);
}

export function* GetProviderStatus() {
  yield takeLatest(GetProvStatus.type, ProvStatusCheck);
}
