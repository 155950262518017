import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetList } from "../../../StateManagement/Reducers/HealthProfileState";
import {
  Div,
  Button,
  PageTitle,
  Table,
  TableHead,
  TableHeadCell,
  TableBodyCell,
  TableRow,
  TableBody,
  SpanCont,
  Image,
} from "../../StyledComponents";
import {
  Container,
  Tabs,
  TabPanel,
  AvatarFrame,
  Avatar,
  AvatarLetter,
  AvatarImage,
  StatusDivDash,
  TableHeadCellAdj,
  TableBodyCellAdj,
  TableHeadCellWid,
  StatusTextFnt,
} from "./styles";

import EmptyData from "../../StyledComponents/EmptyData";
import padImage from "../../../Assets/images/Vector.png";
import { TitleLabel } from "../../HomeWork/Styles";
import { IoMdArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentslist } from "../../../StateManagement/Reducers/DocumentsState";
import { StatusText } from "../../Document/DocumentList/styles";
import { SpanContAdj } from "../Styles";

const DocumentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cell, setCell] = useState("Document");

  const [title, setTitle] = useState("Documents");
  const [type, SetType] = useState("medical_problem");
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  DBhost = "default";

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const GroupData = useSelector((state) => state.HealthProfile.List);
  const DocumentsList = useSelector((state) => state.Document.Documentlist);

  return (
    <>
      <Container>
        <Div padding="20px" display="flex" backColor="none">
          <PageTitle fontWeight="600" fontSize="24px" lineHeight="33px">
            {title}
          </PageTitle>
          <Button
            background="#fafafa"
            borderRadius="90px"
            height="33px"
            border="1px solid #fafafa"
            marginLeft="auto"
            padding="6px 16px 8px 16px"
            color="#667085"
            fontSize="14px"
            lineHeight="19px"
            onClick={() => {
              navigate("/documents", {
                state: { type: { type } },
              });
            }}
          >
            View All
            <IoMdArrowForward
              viewBox="-120 -100 512 512"
              width="16"
              height="16"
            />
          </Button>
        </Div>
        <Div margin="0px 0px 0px 0px" backColor="none">
          <Table tableLayout="fixed">
            <TableHead background="#f7f7f7">
              <TableRow>
                <TableHeadCellAdj
                  color="#464646"
                  lineHeight="19.12px"
                  padding="12px 12px 12px 20px"
                  fontWeight="500"
                  width="25%"
                >
                  {cell}
                </TableHeadCellAdj>
                <TableHeadCellWid
                  color="#464646"
                  lineHeight="19.12px"
                  padding="12px"
                  fontWeight="500"
                  width="20%"
                >
                  Created Date
                </TableHeadCellWid>
                <TableHeadCellWid
                  color="#464646"
                  lineHeight="19.12px"
                  padding="12px"
                  fontWeight="500"
                  width="20%"
                >
                  Shared by
                </TableHeadCellWid>
                <TableHeadCell
                  color="#464646"
                  lineHeight="19.12px"
                  padding="12px 0px 12px 20px"
                  fontWeight="500"
                  width="20%"
                >
                  Status
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DocumentsList.length !== 0 ? (
                <>
                  {DocumentsList.slice(0, 4).map((row, ind) => (
                    <TableRow key={ind}>
                      <TableBodyCellAdj
                        bodered
                        textAlign="left"
                        color="#263446"
                        lineHeight="19.12px"
                        padding="12px 12px 12px 20px"
                        fontWeight="500"
                      >
                        <Div
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <AvatarFrame marginRight="6px">
                            <Image loading="lazy" src={padImage} />
                          </AvatarFrame>

                          <TitleLabel
                            fontFamily="'manrope',sans-serif"
                            fontSize="14px"
                            fontHeight="19.12px"
                            fontWeight="500"
                            color="#263446"
                            title={row.form_name}
                            paddingTop="0px"
                          >
                            {row.form_name}
                          </TitleLabel>
                        </Div>
                      </TableBodyCellAdj>
                      <TableBodyCell
                        bodered
                        textAlign="left"
                        color="#7D8592"
                        lineHeight="19.12px"
                        padding="12px"
                        fontWeight="500"
                      >
                        {row.date}
                      </TableBodyCell>
                      <TableBodyCell
                        bodered
                        textAlign="left"
                        color="#263446"
                        lineHeight="20px"
                        padding="12px 4px 12px 12px"
                        fontWeight="500"
                      >
                        <Div display="flex">
                          {row.prov_img_url === null ? (
                            <Avatar
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <AvatarLetter>{row.prov_name_split}</AvatarLetter>
                            </Avatar>
                          ) : (
                            <AvatarImage
                              src={File_URL + row.prov_img_url}
                              alt="image-failed"
                              loading="lazy"
                              marginRight="0px"
                            />
                          )}

                          <SpanContAdj
                            fontFamily="manrope"
                            color="#263446"
                            lineHeight="20px"
                            fontWeight="400"
                            fontSize="14px"
                            marginLeft="4px"
                            title={row.username}
                          >
                            {row.username}
                          </SpanContAdj>
                        </Div>
                      </TableBodyCell>

                      <TableBodyCell padding="16px 0px 10px 20px;" bodered>
                        {row.status === "In Review" && (
                          <StatusDivDash
                            width="80px"
                            background="rgba(32, 201, 172, 0.1)"
                          >
                            <StatusTextFnt color="#20c9ac">
                              {row.status}
                            </StatusTextFnt>
                          </StatusDivDash>
                        )}
                        {row.status === "New" && (
                          <StatusDivDash
                            width="50px"
                            background=" rgba(0, 165, 255, 0.1)"
                          >
                            <StatusText color="#00a5ff">
                              {row.status}
                            </StatusText>
                          </StatusDivDash>
                        )}
                        {row.status === "Locked" && (
                          <StatusDivDash
                            width="80px"
                            background="rgba(132, 129, 138, 0.1)"
                          >
                            <StatusText color="#2e2c34">
                              {row.status}
                            </StatusText>
                          </StatusDivDash>
                        )}
                      </TableBodyCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableBodyCell
                    padding="0px 20px 0px 20px"
                    colSpan={3}
                    textAlign="center"
                  >
                    <EmptyData width="175px" height="175px" />
                  </TableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Div>
      </Container>
    </>
  );
};

export default DocumentDashboard;
