import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import {
  setOtp,
  setEmailRes,
  setReEmailRes,
  setPassRes,
  setSignUp,
  SetLoginStatus,
  SetProvStatus,
} from "../../StateManagement/Reducers/PreLoginState";

import {
  LoginEmailCheck_Api,
  LoginEmailCheckNew_Api,
  PasswordResetEmailCheck_Api,
  LoginPasswordSet_Api,
  SignUp_Api,
  WPortalApi,
  CheckProvStatusApi,
} from "../Apis/PreLoginApi";
import moment from "moment";

export function* LoginEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "westside");

  const mode = payload.mode;
  try {
    const res = yield call(
      mode === "old" ? LoginEmailCheck_Api : LoginEmailCheckNew_Api,
      email
    );
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "westside");
      yield put(setOtp(Data.output));
      localStorage.setItem("OTP", Encrypt_Value(Data.output, "westside"));
      const oneMinute = moment().add(1, "minute").format("HH:mm:ss");
      localStorage.setItem("OTP_time", Encrypt_Value(oneMinute, "westside"));
      yield put(setEmailRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* LoginPasswordSetApi({ payload }) {
  const data = Encrypt_Value(payload.type, "westside");

  try {
    const res = yield call(LoginPasswordSet_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "westside");
      yield put(setPassRes(Data.status));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PasswordResetEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "westside");

  try {
    const res = yield call(PasswordResetEmailCheck_Api, email);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "westside");
      yield put(setOtp(Data.output));
      localStorage.setItem("OTP", Encrypt_Value(Data.output, "westside"));
      const oneMinute = moment().add(1, "minute").format("HH:mm:ss");
      localStorage.setItem("OTP_time", Encrypt_Value(oneMinute, "westside"));
      yield put(setReEmailRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SignUpApi({ payload }) {
  const data = Encrypt_Value(payload, "westside");
  try {
    const res = yield call(SignUp_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "westside");
      yield put(setSignUp(Data.status));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* LogChecking({ payload }) {
  try {
    const res = yield call(WPortalApi, payload);
    if (res.status === 200) {
      yield put(SetLoginStatus(res.data));
    }
  } catch (e) {
    yield put(SetLoginStatus(""));
    console.log(e.message);
  }
}

export function* ProvStatusCheck({ payload }) {
  try {
    const res = yield call(CheckProvStatusApi, payload);
    if (res.status === 200) {
      yield put(SetProvStatus(res.data));
    }
  } catch (e) {
    yield put(SetProvStatus(""));
    console.log(e.message);
  }
}
