import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Sucess from "../../Modules/StyledComponents/Toastify/Success";
import Error from "../../Modules/StyledComponents/Toastify/Error";

export const DocumentsSlice = createSlice({
  name: "Document",
  initialState: {
    Loading: true,
    Documentlist: [],
    DownloadDocumentlist: "",
    ViewEditData: "",
    signaturesavesignID: "",
    deletetempdoc: "",
    previewdownload: "",
    Refresh: "",
  },
  reducers: {
    getDocumentslist: (state) => {
      state.Loading = true;
      state.Refresh = "";
    },
    setDocumentslist: (state, action) => {
      state.Documentlist = action.payload;
      state.Loading = false;
    },

    getInsertDocumentslist: (fileslist) => {
      return fileslist;
    },

    getUpdateDocumentslist: (id) => {
      return id;
    },

    getDeleteDocumentslist: (ids) => {
      return ids;
    },

    getPreviewDownload: (filename) => {
      return filename;
    },
    setPreviewDownload: (state, action) => {
      state.previewdownload = action.payload;
      state.Loading = false;
    },

    getPreviewDocument: (ids) => {
      return ids;
    },
    setPreviewDocument: (state, action) => {
      state.DownloadDocumentlist = action.payload;
      state.Loading = false;
    },
    getViewEditDocument: (ids) => {
      return ids;
    },
    setViewEditDocument: (state, action) => {
      state.ViewEditData = action.payload;
      state.Loading = false;
    },
    SetSignatureSaveSignId: (state, action) => {
      state.signaturesavesignID = action.payload;
    },

    getEditDataEmpty: (state, action) => {
      state.ViewEditData = "";
    },

    getDeleteTempDoc: (filename) => {
      return filename;
    },
    setDeleteTempDoc: (state, action) => {
      state.deletetempdoc = action.payload;
      state.previewdownload = "";
      state.DownloadDocumentlist = "";
      state.Loading = false;
    },

    getsavesignature: (signaturedata) => {
      return signaturedata;
    },
    getDocFirstSave: (docdata) => {
      return docdata;
    },

    Refresh: (state, action) => {
      if (isNaN(action.payload)) {
        toast(<Error msg={action.payload} />, {
          containerId: "B",
          className: "error_badge",
        });
      } else {
        let message = "";

        if (action.payload === 1) message = "Documents Uploaded Successfully.";
        if (action.payload === 2)
          message = "Document Sent to Review Successfully.";
        if (action.payload === 3) message = "Document Deleted Successfully.";
        if (action.payload === 4) message = "Document Signed Successfully.";
        if (action.payload === 5) message = "Document Updated Successfully.";

        toast(<Sucess msg={message} />, {
          containerId: "A",
        });
      }

      state.Refresh = action.payload;
    },
  },
});

export const {
  getDocumentslist,
  setDocumentslist,

  getInsertDocumentslist,
  getUpdateDocumentslist,
  getDeleteDocumentslist,

  getPreviewDocument,
  setPreviewDocument,
  getViewEditDocument,
  setViewEditDocument,
  SetSignatureSaveSignId,
  getEditDataEmpty,

  getDeleteTempDoc,
  setDeleteTempDoc,

  getPreviewDownload,
  setPreviewDownload,

  getsavesignature,
  getDocFirstSave,

  Refresh,
} = DocumentsSlice.actions;

export default DocumentsSlice.reducer;
