import axiosInst from "../../Config";

export const LoginEmailCheck_Api = async (data) =>
  await axiosInst.post(`/westsideportal/sendverificationemail`, data);

export const LoginEmailCheckNew_Api = async (data) =>
  await axiosInst.post(
    `/westsideportal/sendverificationemailfornewpatient`,
    data
  );

export const PasswordResetEmailCheck_Api = async (data) =>
  await axiosInst.post(`/westsideportal/sendverificationemailforreset`, data);

export const LoginPasswordSet_Api = async (data) =>
  await axiosInst.post(`/westsideportal/setpatientpassword`, data);

export const SignUp_Api = async (data) =>
  await axiosInst.post(`/westsideportal/signup`, data);

export const WPortalApi = async (data) =>
  await axiosInst.get(
    `/westsideportal/WPortalLoginCheck?pid=${data.pid}&uid=${data.uid}&type=${data.type}`
  );

export const CheckProvStatusApi = async (data) =>
  await axiosInst.get(`/westsideportal/getProvChatStatus?uid=${data}`);
