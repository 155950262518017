import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import Error from "../../../Modules/StyledComponents/Toastify/Error";

import {
  changeAppointmentData,
  getOnsiteAppReq,
  clearAppointmentData,
} from "../../../StateManagement/Reducers/AppointmentsState";

import {
  Table,
  TextContent,
  Text,
  NormalImage,
  Button,
  PageTitle,
  Div,
  SpanCont,
} from "../../StyledComponents";
import {
  Tablewhole,
  PastTopTitle,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  TableRow,
  ResText,
  RespTitle,
} from "./styles";

import { InitialsText } from "../AddAppointment/Styles";

import Empt_img from "../../../Assets/images/Empty_Data.png";

const PastAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [changeToText, SetChangeToText] = useState(false);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  let DBhost = "default";

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const pastApptDetails = useSelector((state) => state.Appointments.PastAppt);
  const OnsiteAppReq = useSelector((state) => state.Appointments.OnsiteAppReq);

  const imageError = () => {
    SetChangeToText(!changeToText);
  };

  const scheduleapp = () => {
    if (OnsiteAppReq["allow_portal"] === "YES") {
      // if (OnsiteAppReq["individual_patient"] === "0") {
      navigate("/appointment/add_appointment");
      dispatch(clearAppointmentData(""));
      // } else if (
      //   OnsiteAppReq["individual_patient"] === "1" &&
      //   OnsiteAppReq["newpat_allow"] === "1"
      // ) {
      //   navigate("/appointment/add_appointment");
      //   dispatch(clearAppointmentData(""));
      // } else {
      //   toast(<Error msg="You're not allow to Make an Appointment" />, {
      //     containerId: "B",
      //     className: "error_badge",
      //   });
      // }
    } else {
      toast(<Error msg="You're not allow to Make an Appointment......" />, {
        containerId: "B",
        className: "error_badge",
      });
    }
  };

  useEffect(() => {
    dispatch(changeAppointmentData({ name: "changeUrl", value: "" }));
  }, []);

  return (
    <>
      <Tablewhole>
        <PastTopTitle display="flex" justifyCont="space-between">
          <RespTitle fontSize="20px" fontWeight="500" alignItem="left">
            Past Appointment
          </RespTitle>
          <Button
            background="#2C7BE5"
            hoverBackColor="#005FB2"
            className="save_active"
            display="flex"
            padding="0px 12px"
            alignItem="center"
            onClick={scheduleapp}
          >
            <Text
              color="#FFFFFF"
              fontWeight="400"
              fontSize="1.5rem"
              marginRight="5px"
              padding="10px 12px"
              width="0%"
            >
              +
            </Text>
            <Text
              color="#FFFFFF"
              fontWeight="600"
              fontSize="12px"
              padding="10px 12px 10px 0px"
              id="apt_pst-sch"
            >
              Schedule Appointment
            </Text>
          </Button>
        </PastTopTitle>
        <Table height="calc(100% - 103px)">
          <TableHead
            display="flex"
            width="100%"
            justifyCont="space-between"
            tableLayout="fixed"
            background="#FFFFFF"
            color="#8D98AF"
            paddingRight="20px"
          >
            <TableRow
              display="grid"
              tempColumns="30% 20% 20% 17% 13%"
              tableLayout="fixed"
            >
              <TableHeadCell padding="16px 0 16px 20px" textAlign="left">
                Provider
              </TableHeadCell>
              <TableHeadCell textAlign="left" padding="16px 0 16px 20px">
                Date
              </TableHeadCell>
              <TableHeadCell
                textAlign="center"
                padding="16px 0 16px 20px"
                paddingLeft="0px"
              >
                Time
              </TableHeadCell>
              <TableHeadCell textAlign="left" padding="16px 0 16px 20px">
                Office
              </TableHeadCell>
              <TableHeadCell textAlign="left" padding="16px 0 16px 0">
                Action
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody
            display="block"
            height="calc(100% - 48px)"
            overflowX="hidden"
            width="100%"
            paddingRight="4px"
          >
            {pastApptDetails.length !== 0 ? (
              <>
                {pastApptDetails.map((details) => (
                  <TableRow
                    tableLayout="fixed"
                    className="hover_row"
                    display="grid"
                    tempColumns="30% 20% 20% 17% 13%"
                    // justifyCont="space-between"
                    // margin="16px 0 0 10px"
                  >
                    <TableBodyCell padding="16px 0 16px 20px">
                      <SpanCont display="flex" flexDirection="row">
                        {details.img_url &&
                        details.img_url !== null &&
                        !changeToText ? (
                          <NormalImage
                            loading="lazy"
                            height="40px"
                            width="40px"
                            borderRadius="50%"
                            marginRight="8px"
                            src={File_URL + details.img_url}
                            onError={imageError}
                          />
                        ) : (
                          <InitialsText
                            margin="0 6px 0 0"
                            padding="3px 6px"
                            height="40px"
                            width="40px"
                            fontSize="20px"
                          >
                            {details.initials}
                          </InitialsText>
                        )}
                        <ResText
                          wordBreak="break-word"
                          mt="0px"
                          mb="0px"
                          fontWeight="600"
                          fontSize="14px"
                          width="110px"
                          align="left"
                          display="flex"
                          alignItems="center"
                        >
                          {details.UserName}
                        </ResText>
                      </SpanCont>
                    </TableBodyCell>
                    <TableBodyCell
                      padding="16px 0px"
                      // width="120px"
                      display="flex"
                      alignItems="center"
                    >
                      {details.Date}
                    </TableBodyCell>
                    <TableBodyCell
                      padding="16px 0px"
                      // width="140px"
                      display="flex"
                      alignItems="center"
                    >
                      {details.Time}
                    </TableBodyCell>
                    <TableBodyCell
                      padding="16px 0 16px 20px"
                      // width="120px"
                      display="flex"
                      alignItems="center"
                      wordBreak="break-word"
                    >
                      {details.facility_name}
                    </TableBodyCell>
                    <TableBodyCell
                      padding="16px 0px"
                      display="flex"
                      alignItems="center"
                    >
                      {details.pc_apptstatus === "COMPLETED" ? (
                        <SpanCont
                          borderRadius="4px"
                          backColor="rgba(32, 201, 172, 0.1)"
                          fontWeight="600"
                          padding="4px 8px 4px 8px"
                          color="#20c9ac"
                          fontSize="12px"
                          textAlign="center"
                          wordBreak="break-word"
                        >
                          Complete
                        </SpanCont>
                      ) : (
                        <SpanCont
                          borderRadius="4px"
                          backColor="rgba(132, 129, 138, 0.1)"
                          fontWeight="600"
                          padding="4px 8px 4px 8px"
                          color="#2e2c34"
                          fontSize="12px"
                          textAlign="center"
                          wordBreak="break-word"
                          // width="70px"
                        >
                          Cancel
                        </SpanCont>
                      )}
                    </TableBodyCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                <TableRow
                  display="flex"
                  alignItem="center"
                  justifyCont="center"
                >
                  <TableBodyCell colSpan={5}>
                    <NormalImage
                      loading="lazy"
                      src={Empt_img}
                      height="230px"
                      width="308px"
                    />
                  </TableBodyCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Tablewhole>
    </>
  );
};

export default PastAppointment;
