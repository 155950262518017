import React, { memo } from "react";
import { CommonDiv, FlexJustCenter } from "../StyledComponents";
import EmptyData from "../StyledComponents/EmptyData";

import {
  Avatar,
  AvatarImage,
  AvatarLetter,
  Badge,
  Card,
  DiscLabel,
  Flexbetween,
  GridContainer,
  Image,
  Thumpnail,
  TitleLabel,
  UserLabbel,
} from "./Styles";

const GridView = ({ data, handleOpen, size }) => {
  const B_Url =
    process.env.REACT_APP_BACKEND_FILE_ACCESS +
    "/sites/default/Education_Files/";
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  let DBhost = "default";

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  return (
    <GridContainer>
      {data.length !== 0 ? (
        data.map((item, index) => (
          <Card
            onClick={() => handleOpen(item)}
            key={index}
            size={
              size === 1
                ? "100%"
                : size === 2
                ? "47%"
                : size === 3
                ? "30%"
                : "337px"
            }
          >
            <Thumpnail src={B_Url + item.thumbnailUrl} />
            <Flexbetween>
              <TitleLabel title={item.title}>{item.title}</TitleLabel>
              <Badge>{item.fileType}</Badge>
            </Flexbetween>

            <DiscLabel wordBreak="break-all">{item.description}</DiscLabel>
            <FlexJustCenter margin="4px 16px 12px 16px">
              {item.userUrl ? (
                <AvatarImage src={File_URL + item.userUrl} />
              ) : (
                <Avatar width="28px" height="28px">
                  <AvatarLetter
                    display="flex"
                    justifyContent="center"
                    paddingTop="6px"
                  >
                    {item.UploadedBy.charAt(0).toUpperCase()}
                  </AvatarLetter>
                </Avatar>
              )}
              <UserLabbel>{item.UploadedBy}</UserLabbel>
            </FlexJustCenter>
            <Flexbetween
              padding="8px 16px"
              borderTop="1px solid #EBEAED"
              margin="10px 16px 2px 16px"
            >
              <UserLabbel lineHeight="16px" className="assigned_margin">
                Assigned {item.Assigned}
              </UserLabbel>
              <UserLabbel lineHeight="16px" className="assigned_marginright">
                Watched {item.Watched}
              </UserLabbel>
            </Flexbetween>
          </Card>
        ))
      ) : (
        <CommonDiv height="100%" width="100%" flexCenterAll>
          <EmptyData />
        </CommonDiv>
      )}
    </GridContainer>
  );
};

export default memo(GridView);
