import styled from "styled-components";

export const Div = styled.div`
  cursor: pointer;
`;

export const SubHeading = styled.h6`
  font-size: 18px;
  line-height: 19px;
  font-weight: bold;
  color: #232934;
`;

export const DropDown = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 8px;
  svg {
    font-size: 12px;
    margin-left: 7px;
  }
`;

export const DropDownListContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 179px;
  position: absolute;
  z-index: 100;
`;

export const DropDownList = styled.ul``;

export const ListItem = styled.li`
  list-style: none;
  font-size: 14px;
  line-height: 16px;
  color: #414141;
  padding: 10px 16px;
  cursor: poniter;
  &:hover {
    background: #ececec;
    cursor: pointer;
  }
`;
