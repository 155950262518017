import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import DocumentsReducer from "./Reducers/DocumentsState";
import PreLoginReducer from "./Reducers/PreLoginState";
import LoginReducer from "./Reducers/LoginState";
import TopNavReducer from "./Reducers/TopNavbarState";
import HomeWorkReducer from "./Reducers/HomeWorkState";
import SettingReducer from "./Reducers/SettingState";
import AppointmentsReducer from "./Reducers/AppointmentsState";
import MessengerReducer from "./Reducers/MessengerState";
import InsuranceReducer from "./Reducers/InsuranceState";
import HealthProfileReducer from "./Reducers/HealthProfileState";

import RootSaga from "../MiddleWare";

const Saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    PreLogin: PreLoginReducer,
    Login: LoginReducer,
    Document: DocumentsReducer,
    TopNavbar: TopNavReducer,
    Appointments: AppointmentsReducer,
    HomeWork: HomeWorkReducer,
    Setting: SettingReducer,
    Messenger: MessengerReducer,
    Insurance: InsuranceReducer,
    HealthProfile: HealthProfileReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(Saga),
});

Saga.run(RootSaga);

export default store;
