import { takeLatest } from "redux-saga/effects";
import {
  GetSingleInsDetail,
  postSingleInsDetail,
} from "../../StateManagement/Reducers/InsuranceState";
import { GetInsurancedata, postInsurancedata } from "./InsuranceSagaWorkers";

export function* SingleInsuranceDetail() {
  yield takeLatest(postSingleInsDetail.type, postInsurancedata);
}
export function* GetInsuranceDetail() {
  yield takeLatest(GetSingleInsDetail.type, GetInsurancedata);
}
