import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { GetCancelAppt } from "../../../StateManagement/Reducers/AppointmentsState";

import {
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  Button,
  Div,
} from "../../StyledComponents";

import { CancelApptInput, ModalDialog } from "./styles";

const AppointmentCancel = (props) => {
  const [Text, SetText] = useState("");
  const [savebtndisabled, setsavebtndisabled] = useState(false);
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const dispatch = useDispatch();

  const Confirmation = (e) => {
    setsavebtndisabled(true);
    setTimeout(() => {
      setsavebtndisabled(false);
    }, 1200);
    props.HidePopup();
    e.preventDefault();
    SetText("");
    dispatch(
      GetCancelAppt({
        Cancel_reason: Text,
        pc_eid: appointmentData["cancelID"],
      })
    );
  };

  return (
    <Modal width="100%" height="100%" show={props.show}>
      <ModalContainer justifyContent="center">
        <ModalDialog style={{ padding: 20, width: 367 }}>
          <ModalHeader padding="0px" borderLess marginBottom="12px">
            <ModalTitle
              lineHight="27px"
              fontSize="20px"
              fontWeight="500"
              color="#232934"
            >
              Appointment Cancel
            </ModalTitle>
          </ModalHeader>
          <ModalBody
            marginBottom="16px"
            fontWeight="400"
            fontSize="14px"
            color="#263238;"
          >
            Kindly Specify the reason for cancelling the appointment with us?
          </ModalBody>
          <CancelApptInput
            placeholder="Enter the Reason"
            onChange={(e) => SetText(e.target.value)}
          ></CancelApptInput>
          <Div display="flex">
            <Button
              height="32px"
              background="#FFF"
              borderRadius="4px"
              hoverBackColor="rgba(244, 246, 249, 0.75)"
              border=" 1px solid #2C7BE5"
              font-family="Work Sans, sans-serif"
              cursor="pointer"
              marginLeft="auto"
              marginRight="20px"
              width="110px"
              color="#2C7BE5"
              padding="6px 16px"
              onClick={props.HidePopup}
              id="cnl_apt-btn"
            >
              Cancel
            </Button>
            <Button
              height="32px"
              background="#2C7BE5"
              hoverBackColor="#005FB2"
              borderRadius="4px"
              font-family="Work Sans, sans-serif"
              cursor="pointer"
              width="110px"
              color="#ffffff"
              padding="6px 16px"
              className="save_active"
              disabled={savebtndisabled}
              onClick={Confirmation}
            >
              Confirm
            </Button>
          </Div>
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default AppointmentCancel;
