import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Error from "../../Modules/StyledComponents/Toastify/Error";
import { Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import moment from "moment";

export const preLoginSlice = createSlice({
  name: "PreLogin",
  initialState: {
    clientLoading: false,
    EmailResp: {},
    mode: "",
    otp: {},
    passWordRes: {},
    signup: [],
    logo: [],
    ReEmailResp: {},
    patientUrl: [],
    WestsidePortalLogin: "",
    ProvStatus: "",
  },
  reducers: {
    GetEmailRes: (state) => {
      return state;
    },
    setEmailRes: (state, action) => {
      state.EmailResp = action.payload;
    },
    GetReEmailRes: (state) => {
      return state;
    },
    setReEmailRes: (state, action) => {
      state.ReEmailResp = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    GetPassRes: (state) => {
      return state;
    },
    setPassRes: (state, action) => {
      state.passWordRes = action.payload;
      if (
        action.payload ===
        "The password you entered must not be an old password"
      ) {
        toast(
          <Error
            msg={"The password you entered must not be an old password"}
          />,
          {
            containerId: "B",
            className: "error_badge",
          }
        );
      }
    },
    GetSignUp: (state) => {
      return state;
    },
    setSignUp: (state, action) => {
      state.signup = action.payload;
    },
    GetLogo: (state) => {
      return state;
    },
    SetLogo: (state, action) => {
      if (action.payload && action.payload !== null) {
        state.logo = action.payload;
      }
    },
    GetPatientUrl: (state) => {
      return state;
    },
    SetPatientUrl: (state, action) => {
      state.PatientUrl = action.payload;
    },

    GetLoginStatus: (state) => {
      return state;
    },
    SetLoginStatus: (state, action) => {
      state.WestsidePortalLogin = action.payload;
    },

    GetProvStatus: (state) => {
      return state;
    },
    SetProvStatus: (state, action) => {
      state.ProvStatus = action.payload;
    },
  },
});

export const {
  GetEmailRes,
  setEmailRes,
  GetReEmailRes,
  setReEmailRes,
  setMode,
  setOtp,
  GetPassRes,
  setPassRes,
  GetSignUp,
  setSignUp,
  GetLogo,
  SetLogo,
  GetPatientUrl,
  SetPatientUrl,
  GetLoginStatus,
  SetLoginStatus,
  GetProvStatus,
  SetProvStatus,
} = preLoginSlice.actions;

export default preLoginSlice.reducer;
