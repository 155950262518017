import { call, put } from "redux-saga/effects";
import { getInsuranceApi, postInsuranceApi } from "../Apis/InsuranceApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import {
  InsuranceResponse,
  SetSingleInsDetail,
} from "../../StateManagement/Reducers/InsuranceState";

export function* postInsurancedata({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "westside");
  try {
    const res = yield call(postInsuranceApi, ecrypted);

    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "westside");
      yield put(InsuranceResponse(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(InsuranceResponse("Unable to add Details"));
  }
}

export function* GetInsurancedata({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "westside");
  try {
    const res = yield call(getInsuranceApi, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "westside");
      yield put(SetSingleInsDetail(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetSingleInsDetail([]));
  }
}
