import { takeLatest } from "redux-saga/effects";
import {
  GetPatGenderDetails,
  GetPatMaritalStatus,
  GetPatLangDetails,
  GetPatRaceDetails,
  GetPatientProfileUpdate,
  GetPatientProfileRemove,
  GetPatientDetailUpdate,
  GetEmailExist,
  Getprofileupdatestatus,
  getInsertEventType,
  GetEventTypeData,
  GetPatientState,
  GetFeedback,
  GetResetPassword,
  GetCheckPortalPassword,
  GetRelationState,
  GetPatSearch,
} from "../../StateManagement/Reducers/SettingState";
import {
  PatGenderDetailsApi,
  InsertEventTypeApi,
  GetEventTypeApi,
  PatMaritalStatusApi,
  PatLangDetailsApi,
  PatRaceDetailsApi,
  PatientProfileUpdateApi,
  PatientProfileRemoveApi,
  PatientDetailUpdateApi,
  EmailExistApi,
  ProfileUpdateStatusApi,
  PatientStateApi,
  // FeedbackApi,
  // PasswordValidation,
  // CheckPrevPassword,
  RelationStateApi,
  PatSearchAPI,
} from "./SettingsSagaWorkers";

export function* PatGenderDetails() {
  yield takeLatest(GetPatGenderDetails.type, PatGenderDetailsApi);
}
export function* InsertEventType() {
  yield takeLatest(getInsertEventType.type, InsertEventTypeApi);
}
export function* GetEventType() {
  yield takeLatest(GetEventTypeData.type, GetEventTypeApi);
}

export function* PatMaritalStatus() {
  yield takeLatest(GetPatMaritalStatus.type, PatMaritalStatusApi);
}
export function* PatLangDetails() {
  yield takeLatest(GetPatLangDetails.type, PatLangDetailsApi);
}
export function* PatRaceDetails() {
  yield takeLatest(GetPatRaceDetails.type, PatRaceDetailsApi);
}
export function* PatientProfileUpdate() {
  yield takeLatest(GetPatientProfileUpdate.type, PatientProfileUpdateApi);
}
export function* PatientProfileRemove() {
  yield takeLatest(GetPatientProfileRemove.type, PatientProfileRemoveApi);
}
export function* PatientDetailUpdate() {
  yield takeLatest(GetPatientDetailUpdate.type, PatientDetailUpdateApi);
}
export function* EmailExist() {
  yield takeLatest(GetEmailExist.type, EmailExistApi);
}
export function* ProfileUpdateStatus() {
  yield takeLatest(Getprofileupdatestatus.type, ProfileUpdateStatusApi);
}
export function* PatientStatus() {
  yield takeLatest(GetPatientState.type, PatientStateApi);
}

export function* Relationships() {
  yield takeLatest(GetRelationState.type, RelationStateApi);
}

export function* SearchPatient() {
  yield takeLatest(GetPatSearch.type, PatSearchAPI);
}
