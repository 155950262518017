import { takeLatest } from "redux-saga/effects";
import {
  GetUserDetails,
  GetNotificationDetails,
  GetNotificationUpdate,
  GetNotificationDelete,
} from "../../StateManagement/Reducers/TopNavbarState";
import {
  UserDetailsApi,
  NotificationDetailsApi,
  NotificationUpdateApi,
  NotificationDeleteApi,
} from "./TopNavbarSagaWorkers";

export function* UserDetails() {
  yield takeLatest(GetUserDetails.type, UserDetailsApi);
}
export function* NotificationDetails() {
  yield takeLatest(GetNotificationDetails.type, NotificationDetailsApi);
}

export function* NotificationUpdate() {
  yield takeLatest(GetNotificationUpdate.type, NotificationUpdateApi);
}
export function* NotificationDelete() {
  yield takeLatest(GetNotificationDelete.type, NotificationDeleteApi);
}
