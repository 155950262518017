import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "westside");

export const ProviderListApi = async () =>
  await axiosInst.get(`/westsideportal/Providerlist?pid=${Pat_ID}`);

export const MessageListApi = async (credential) =>
  await axiosInst.post(`/westsideportal/patient/getMessagelist`, credential);

export const MessageSendApi = async (credential) =>
  await axiosInst.post(`/westsideportal/patient/sendMessage`, credential);

export const PreviewDownloadApi_1 = async (credential) =>
  await axiosInst.put(`/westsideportal/PreviewDownload`, credential);

export const UnreadCountUpdateApi = async (credential) =>
  await axiosInst.put(`/westsideportal/patient/unreadCountUpdate`, credential);

export const PinchatApi = async (credential) =>
  await axiosInst.put(`/westsideportal/patient/pinchat`, credential);
