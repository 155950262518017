import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TopNav from "../TopNav";
import Nextappt from "./NextAppointment";
import ProblemDashboard from "../DashBoard/ProblemDashboard";
import Loading from "../StyledComponents/Loading";

import { BodyContainer, NormalImage, Div } from "../StyledComponents";
import {
  PatientInfo,
  LightText,
  SubContainer,
  Div_1,
  CurveImg,
} from "./Styles";

import BlueCurve from "../../Assets/images/Ellipse_2.png";
import DocumentDashboard from "./DocumentDashboard";
import { getDocumentslist } from "../../StateManagement/Reducers/DocumentsState";
import { GetList } from "../../StateManagement/Reducers/HealthProfileState";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [PopupShow, setPopupShow] = useState(false);

  const DocLoading = useSelector((state) => state.Document.Loading);
  const HealthLoading = useSelector(
    (state) => state.HealthProfile.HealthLoading
  );
  const PatName = useSelector((state) => state.TopNavbar.userDetails.patname);
  const img_url = useSelector((state) => state.TopNavbar.userDetails.img_url);
  const initials = useSelector((state) => state.TopNavbar.userDetails.initials);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  DBhost = "default";

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  useEffect(() => {
    dispatch(getDocumentslist({ filtertype: "All Documents" }));
    dispatch(GetList("medical_problem"));
  }, []);
  return (
    <>
      <TopNav title="Dashboard" />
      {DocLoading || HealthLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <BodyContainer
          paddingTop="44px"
          paddingRight="28px"
          paddingBottom="80px"
          paddingLeft="24px"
        >
          <PatientInfo>
            {img_url === "" || img_url === null ? (
              <Div
                border="1.5px solid #e9eef9"
                position="relative"
                height="70px"
                width="70px"
                minWidth="70px"
                minHeight="70px"
                borderRadius="50%"
                marginRight="20px"
                padding="4px"
              >
                <CurveImg
                  position="absolute"
                  top="-0.5px"
                  left="38px"
                  width="30px"
                  height="30px"
                  src={BlueCurve}
                />
                <Div
                  background="#A8AEBE"
                  height="100%"
                  width="100%"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="#FFFFFF"
                >
                  {initials}
                </Div>
              </Div>
            ) : (
              <Div
                border="1.5px solid #e9eef9"
                position="relative"
                height="70px"
                width="70px"
                minWidth="70px"
                minHeight="70px"
                borderRadius="50%"
                marginRight="20px"
                padding="4px"
              >
                <CurveImg
                  position="absolute"
                  top="-0.5px"
                  left="38px"
                  width="30px"
                  height="30px"
                  src={BlueCurve}
                />
                <NormalImage
                  loading="lazy"
                  height="100%"
                  width="100%"
                  borderRadius="50%"
                  src={File_URL + img_url}
                />
              </Div>
            )}
            <LightText>Hi, </LightText> {PatName}
          </PatientInfo>

          <SubContainer>
            <Div_1 gridTop height="100%">
              <Nextappt />
              <DocumentDashboard />
              <ProblemDashboard />
            </Div_1>
          </SubContainer>
          {/* <Welcome show={PopupShow} ModalHide={() => setPopupShow(false)} /> */}
        </BodyContainer>
      )}
    </>
  );
};

export default Dashboard;
