import { useEffect, useState } from "react";
import moment from "moment";
import { Decrypt_Value, Encrypt_Value } from "../MiddleWare/EncryptDecrypt";
import axiosInst from "./../Config";
import { useDispatch } from "react-redux";
import { GetLoginStatus } from "../StateManagement/Reducers/PreLoginState";

const useLogout = (startTime) => {
  const dispatch = useDispatch();
  // countdown start
  const [timer, setTimer] = useState(startTime);
  // api start time

  const [token_exp_times, settoken_exp_times] = useState(
    moment(
      localStorage.getItem("token_exp")
        ? Decrypt_Value(localStorage.getItem("token_exp"), "westside")
        : ""
    ).format("YYYY-MM-DD HH:mm A")
  );

  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "westside");

  // ----------------------getting access token using refresh token starts here----------------------

  const refreshToken = () => {
    var val = {
      refresh_token: localStorage.getItem("refreshtoken"),
    };
    let Encrypt_details = Encrypt_Value(val, "westside");
    axiosInst
      .post("/westsideportal/refresh", Encrypt_details)
      .then((response) => {
        let decrypt_val = Decrypt_Value(response.data, "westside");
        let token_enc = decrypt_val.token;
        localStorage.setItem("accesstoken", token_enc);
        localStorage.setItem("refreshtoken", decrypt_val.refresh_token);

        // axiosInst.defaults.headers.common["Authorization"] = decrypt_val.token;

        localStorage.setItem(
          "token_exp",
          Encrypt_Value(decrypt_val.token_exp, "westside")
        );
        settoken_exp_times(
          moment(decrypt_val.token_exp).format("YYYY-MM-DD HH:mm A")
        );
      })
      .catch((e) => {
        console.log(e);
        localStorage.clear();
        window.location.href = "/";
        dispatch(GetLoginStatus({ pid: Pat_ID, uid: 0, type: "logout" }));
      });
  };

  useEffect(() => {
    let currTimes = moment().add(3, "m").format("YYYY-MM-DD HH:mm A");

    if (token_exp_times === currTimes) {
      refreshToken();
    }

    // run use effect after 1min
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 60000);

    const resetTimeout = () => {
      setTimer(startTime);
    };

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });
  return timer;
};

export default useLogout;
