import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDocumentslist } from "../../../StateManagement/Reducers/DocumentsState";

import { FaPlus } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";

import UploadDocument from "../UploadDocument";
import Pagination from "../../StyledComponents/Pagination";
import DocumentTable from "./DocumentTable";

import { Div, Button, Text } from "../../StyledComponents";
import { TitleDiv, DropdownList, DropdownContent } from "./styles";

const DocumentList = ({ documenthead, setdocumenthead }) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();

  const inputNotRef = useRef(null);

  const [drop, setDrop] = useState(false);
  const [isupload, setIsUpload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const DocumentsList = useSelector((state) => state.Document.Documentlist);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = DocumentsList.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(DocumentsList.length / postsPerPage);

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const documentlistselect = (val) => {
    if (val === "1") {
      setdocumenthead("Documents");
      dispatch(getDocumentslist({ filtertype: "All Documents" }));
    }
    if (val === "2") {
      setdocumenthead("Signed Documents");
      dispatch(getDocumentslist({ filtertype: "Signed Documents" }));
    }
    if (val === "3") {
      setdocumenthead("New Documents");
      dispatch(getDocumentslist({ filtertype: "New Documents" }));
    }

    if (val === "4") {
      setdocumenthead("In Review Dcouments");
      dispatch(getDocumentslist({ filtertype: "In Review Dcouments" }));
    }
  };

  const handleClick1 = (e) => {
    if (inputNotRef.current && !inputNotRef.current.contains(e.target)) {
      setDrop(false);
    }
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------ useEffect Start------------------------------------------------------------- //
  useEffect(() => {
    document.addEventListener("mouseup", handleClick1);

    return () => {
      document.addEventListener("mouseup", handleClick1);
    };
  }, []);
  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <>
      <TitleDiv>
        <Div
          display="flex"
          flexDirection="column"
          alignItems="center"
          onClick={() => setDrop(!drop)}
          position="relative"
        >
          <Text
            textAlign="left"
            mb="0px"
            mt="0px"
            fontWeight="400"
            fontSize="14px"
            lineHeight="19px"
            color="#7d8592"
          >
            Document list
          </Text>
          <Text
            fontWeight="500"
            fontSize="20px"
            lineHeight="28px"
            color="#000000"
            mb="0px"
            mt="0px"
            textAlign="left"
            svgmarginLeft="6px"
            svgmarginTop="7px"
            svgfontSize="12px"
          >
            {documenthead}

            <BsChevronDown id="doc_al-svg_log" />
          </Text>
          <DropdownContent
            marginLeft="-11px"
            marginTop="30px"
            left="0px"
            top="50%"
            width="170px"
            display={drop.toString()}
            ref={inputNotRef}
          >
            <DropdownList
              padding="10px"
              onClick={() => documentlistselect("1")}
            >
              Documents
            </DropdownList>
            <DropdownList
              padding="10px"
              onClick={() => documentlistselect("2")}
              id="doc_dd-li_snd"
            >
              Signed Documents
            </DropdownList>
            <DropdownList
              padding="10px"
              onClick={() => documentlistselect("3")}
              id="doc_dd-li_usnd"
            >
              New / UnSigned Documents
            </DropdownList>
            <DropdownList
              padding="10px"
              onClick={() => documentlistselect("4")}
              id="doc_dd-li_rvw"
            >
              In Review Dcouments
            </DropdownList>
          </DropdownContent>
        </Div>

        <Button
          padding="12px 20px"
          height="40px"
          background="#2C7BE5"
          hoverBackColor="#005FB2"
          color="#fff"
          fontWeight="500"
          borderRadius="4px"
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          display="flex"
          svgfontSize="16px"
          svgmarginRight="10px"
          className="save_active"
          onClick={() => setIsUpload(true)}
          id="doc_upd-btn"
        >
          <FaPlus /> Upload Document
        </Button>
      </TitleDiv>

      <DocumentTable docTableData={currentPosts} documenthead={documenthead} />

      <UploadDocument
        show={isupload}
        close={() => setIsUpload(false)}
        setdocumenthead={setdocumenthead}
      />

      {currentPosts.length === 0 || howManyPages === 1 ? (
        ""
      ) : (
        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
      )}
    </>
  );
};

export default DocumentList;
