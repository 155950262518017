import React, { useEffect } from "react";
import {
  Button,
  Div,
  Form,
  FormGroup,
  FormLabel,
  Input,
  RedStar,
  SelectInput,
} from "../StyledComponents";
import {
  ErrorMessage,
  Hrline,
  DisplayerrorText,
  Displayerror,
} from "./EditProfile/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { useNavigate } from "react-router";
import { useRef } from "react";
import { toast } from "react-toastify";
import Error from "../StyledComponents/Toastify/Error";
import "react-image-crop/dist/ReactCrop.css";
import {
  InsuranceResponse,
  GetSingleInsDetail,
  postSingleInsDetail,
} from "../../StateManagement/Reducers/InsuranceState";
import {
  Getprofileupdatestatus,
  SetEmailExist,
} from "../../StateManagement/Reducers/SettingState";
import Success from "../StyledComponents/Toastify/Success";

const Insurance = ({ value, value1, setActiveTab }) => {
  //--------------------------State and  Variables Start---------------------------- //

  const [insuranceType] = useState("primary");
  const [InsuranceName, setInsuranceName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [planId, setPlanId] = useState("");
  const [payerPhone, setPayerPhone] = useState("");
  const [payerFax, setPayerFax] = useState("");
  const [ShowOptions3, setShowOptions3] = useState(false);
  const [insuranceProviderError, setInsuranceProviderError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [disabledsave, setdisabledsave] = useState(true);
  const [disabledsave1, setdisabledsave1] = useState(true);
  const [memberid, setmemberid] = useState("");
  const [memberidError, setmemberidError] = useState("");
  const [copay, setCopay] = useState("");
  const [employer, setEmployer] = useState("");
  const [UpdateinsuranceStatus, setupdateinsurancestatus] = useState(0);
  const [insurancerevertReason, setInsuranceRevertReason] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const pid =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "westside");

  const addInsuranceResponse = useSelector(
    (state) => state.Insurance?.InsuranceRes
  );
  const PatDetails = useSelector((state) => state.TopNavbar.userDetails);
  const vitalsRef = useRef(null);

  //-----------------------------State and  Variables End----------------------------- //
  //-----------------------------Function Start--------------------------------------- //

  const insuranceDetails = useSelector(
    (state) => state.Insurance?.insuranceDetails
  );
  const handlechange = (e) => {
    if (e.target.name === "insuranceName") {
      if (e.target.value.trim() === "") {
        setInsuranceProviderError(true);
      } else {
        setInsuranceProviderError(false);
      }
      setInsuranceName(e.target.value);
    } else if (e.target.name === "memberid") {
      if (e.target.value.trim() === "") {
        setmemberidError(true);
      } else {
        setmemberidError(false);
      }
      setmemberid(e.target.value);
    } else if (e.target.name === "GroupId") {
      if (e.target.value.trim() === "") {
        setGroupError(true);
      } else {
        setGroupError(false);
      }
      setGroupId(e.target.value);
    } else if (e.target.name === "PlanId") {
      if (e.target.value.trim() === "") {
        setPlanError(true);
      } else {
        setPlanError(false);
      }
      setPlanId(e.target.value);
    } else if (e.target.name === "PayerPhone") {
      if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
        setPayerPhone(e.target.value);
      }
    } else if (e.target.name === "PayerFax") {
      if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
        setPayerFax(e.target.value);
      }
    } else if (e.target.name === "copay") {
      setCopay(e.target.value);
    } else if (e.target.name === "employer") {
      setEmployer(e.target.value);
    }
  };
  const updateinsurancestatustimeout = (alertData) => {
    if (alertData !== "0" && alertData !== 0) {
      setupdateinsurancestatus(0);
      dispatch(Getprofileupdatestatus("insurance"));
    }
  };
  const HandleClose = () => {
    navigate("/dashboard/" + pid);
  };
  const handleSave = (e) => {
    e.preventDefault();

    let err = 0;

    if (InsuranceName === "") {
      setInsuranceProviderError(true);
      err = 2;
    }
    if (groupId === "") {
      setGroupError(true);
      err = 2;
    }
    if (planId === "") {
      setPlanError(true);
      err = 2;
    }

    if (memberid === "") {
      setmemberidError(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    }
    setdisabledsave(true);

    dispatch(
      postSingleInsDetail({
        pid: pid,
        type: insuranceType,
        provider: InsuranceName,
        member_id: memberid,
        group_number: groupId,
        policy_number: planId,
        payer_phone: payerPhone,
        payer_fax: payerFax,
        copay: copay,
        subscriber_employer: employer,
      })
    );
  };

  //-----------------------------Function End----------------------------------- //

  //-----------------------------UseEffect Start--------------------------------- //

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (vitalsRef.current && !vitalsRef.current.contains(event.target)) {
        if (ShowOptions3 === true) {
          setShowOptions3(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ShowOptions3]);

  useEffect(() => {
    dispatch(
      GetSingleInsDetail({
        pid: pid,
        type: insuranceType,
      })
    );
  }, []);
  useEffect(() => {
    if (insuranceDetails[0]) {
      setInsuranceName(
        insuranceDetails[0].provider ? insuranceDetails[0].provider : ""
      );
      setGroupId(
        insuranceDetails[0].group_number ? insuranceDetails[0].group_number : ""
      );
      setmemberid(
        insuranceDetails[0].member_id ? insuranceDetails[0].member_id : ""
      );
      setPlanId(
        insuranceDetails[0].policy_number
          ? insuranceDetails[0].policy_number
          : ""
      );
      setPayerPhone(insuranceDetails[0].payer_phone);
      setPayerFax(insuranceDetails[0].payer_fax);
      setCopay(insuranceDetails[0].copay);
      setEmployer(insuranceDetails[0].subscriber_employer);
      setdisabledsave(insuranceDetails[0].showsave);
      setdisabledsave1(insuranceDetails[0].billing_patient);
      setGroupError(false);
      setPlanError(false);
      setInsuranceProviderError(false);
    } else {
      setInsuranceName("");
      setmemberid("");
      setGroupId("");
      setPayerPhone("");
      setPayerFax("");
      setPlanId("");
      setCopay("");
      setEmployer("");
    }
  }, [insuranceDetails]);
  useEffect(() => {
    if (
      PatDetails.update_insurance_status !== "0" &&
      PatDetails.update_insurance_status !== 0
    ) {
      setTimeout(
        () => updateinsurancestatustimeout(PatDetails.update_insurance_status),
        5000
      );
    }
    setupdateinsurancestatus(PatDetails.update_insurance_status);
    setInsuranceRevertReason(PatDetails.insurance_revert_reason);
    dispatch(SetEmailExist(""));
  }, []);
  useEffect(() => {
    if (addInsuranceResponse === "success") {
      toast(<Success msg="Details Sent to Review Successfully" />, {
        containerId: "A",
        className: "success_badge",
      });
      dispatch(InsuranceResponse(""));
    }

    if (addInsuranceResponse !== "" || addInsuranceResponse === "failure") {
      if (
        addInsuranceResponse === "Unable to add Details" ||
        addInsuranceResponse === "failure"
      ) {
        toast(<Error msg="Unable to add Details" />, {
          containerId: "A",
          className: "error_badge",
        });
        dispatch(InsuranceResponse(""));
      }
    }
  }, [addInsuranceResponse]);
  //-----------------------------UseEffect End---------------------------- //

  return (
    <>
      {UpdateinsuranceStatus === 1 || UpdateinsuranceStatus === "1" ? (
        <Displayerror
          display="flex"
          background="#77E0B5"
          width="100%"
          alignItems="center"
          pt="10px"
          pb="10px"
          justifyContent="center"
          color="#FFFFFF"
          mb="20px"
          mt="10px"
        >
          Insurance updated successfully
        </Displayerror>
      ) : (
        <>
          {UpdateinsuranceStatus === 2 || UpdateinsuranceStatus === "2" ? (
            <Displayerror
              background="#FF7676"
              width="100%"
              alignItems="center"
              pt="10px"
              pb="10px"
              justifyContent="center"
              mb="24px"
              mt="10px"
            >
              <DisplayerrorText
                fontFamily="'Inter'"
                fontWeight="600"
                fontSize="16px"
                lineHeight="19px"
                textAlign="center"
                color="#ffffff"
              >
                Insurance updated was Revert
              </DisplayerrorText>
              <DisplayerrorText
                fontFamily="Work Sans"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center"
                color="#ffffff"
                mt="4px"
              >
                {insurancerevertReason}
              </DisplayerrorText>
            </Displayerror>
          ) : (
            <></>
          )}
        </>
      )}
      <Form>
        <Div margin="0px 0px 0px 45px">
          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Insurance Payer <RedStar>*</RedStar>
            </FormLabel>
            <Input
              height="34px"
              backgroundColor="#ffffff"
              border={
                !insuranceProviderError
                  ? "1px solid rgba(46, 46, 46, 0.25)"
                  : "1px solid red"
              }
              hoverborder="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              padding="0 0 0 12px"
              name="insuranceName"
              value={InsuranceName}
              onChange={handlechange}
            />
            {insuranceProviderError && (
              <ErrorMessage>Please enter Insurance Name.</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Member id<RedStar>*</RedStar>
            </FormLabel>
            <Input
              height="34px"
              backgroundColor="#ffffff"
              border={
                !memberidError
                  ? "1px solid rgba(46, 46, 46, 0.25)"
                  : "1px solid red"
              }
              borderRadius="4px"
              padding="0 0 0 12px"
              name="memberid"
              value={memberid}
              onChange={handlechange}
            />
            {memberidError && (
              <ErrorMessage>Please enter Member id.</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup display="grid" width="322px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Group ID <RedStar>*</RedStar>
            </FormLabel>
            <Input
              name="GroupId"
              height="34px"
              backgroundColor="#ffffff"
              border={
                !groupError
                  ? "1px solid rgba(46, 46, 46, 0.25)"
                  : "1px solid red"
              }
              borderRadius="4px"
              padding="0 0 0 12px"
              value={groupId}
              onChange={handlechange}
            />
            {groupError && <ErrorMessage>Please enter Group Id.</ErrorMessage>}
          </FormGroup>

          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginTop="24px"
              marginBottom="8px"
              textAlign="left"
            >
              Plan ID <RedStar>*</RedStar>
            </FormLabel>
            <Input
              name="PlanId"
              height="34px"
              backgroundColor="#ffffff"
              border={
                !planError
                  ? "1px solid rgba(46, 46, 46, 0.25)"
                  : "1px solid red"
              }
              borderRadius="4px"
              padding="0 0 0 12px"
              value={planId}
              onChange={handlechange}
            />
            {planError && <ErrorMessage>Please enter Plan Id.</ErrorMessage>}
          </FormGroup>

          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Insurance Payer Phone
            </FormLabel>
            <Input
              name="PayerPhone"
              height="34px"
              backgroundColor="#ffffff"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              padding="0 0 0 12px"
              value={payerPhone}
              onChange={handlechange}
            />
          </FormGroup>
          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Insurance Payer Fax
            </FormLabel>
            <Input
              name="PayerFax"
              height="34px"
              backgroundColor="#ffffff"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              padding="0 0 0 12px"
              value={payerFax}
              onChange={handlechange}
            />
          </FormGroup>
          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Copay
            </FormLabel>
            <Input
              height="34px"
              backgroundColor="#ffffff"
              border="1px solid rgba(46, 46, 46, 0.25)"
              hoverborder="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              padding="0 0 0 12px"
              name="copay"
              value={copay}
              onChange={handlechange}
            />
          </FormGroup>
          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Employer/Scholar
            </FormLabel>
            <Input
              height="34px"
              backgroundColor="#ffffff"
              border="1px solid rgba(46, 46, 46, 0.25)"
              hoverborder="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              padding="0 0 0 12px"
              name="employer"
              value={employer}
              onChange={handlechange}
            />
          </FormGroup>
        </Div>

        <Hrline ml="-40px" mb="23px" />

        <Div display="flex" marginLeft="45px" marginBottom="31px">
          <Button
            padding="8px 16px"
            height="34px"
            background="#fff"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            color="#2C7BE5"
            marginRight="20px"
            border="1px solid #2C7BE5"
            cursor="pointer"
            onClick={HandleClose}
            className="close_active"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
          >
            Cancel
          </Button>
          <Button
            padding="7px 32px 9px 32px"
            height="34px"
            background="#2C7BE5"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            color="#ffffff"
            border="none"
            cursor="pointer"
            onClick={handleSave}
            disabled={(disabledsave && true) || disabledsave1}
            hoverBackColor="#005FB2"
            className="save_active"
            marginBottom="20px"
          >
            {disabledsave ? "Waiting For Review" : "Save"}
          </Button>
        </Div>
      </Form>
    </>
  );
};

export default Insurance;
