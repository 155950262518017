import { takeLatest } from "redux-saga/effects";
import {
  getDocumentslist,
  getInsertDocumentslist,
  getUpdateDocumentslist,
  getDeleteDocumentslist,
  getPreviewDocument,
  getViewEditDocument,
  getsavesignature,
  getDocFirstSave,
  getDeleteTempDoc,
  getPreviewDownload,
} from "../../StateManagement/Reducers/DocumentsState";

import {
  DocumentListApi,
  InsertDocumentsApi,
  UpdateDocumentApi,
  DeleteDocumentApi,
  DownloadDocumentApi,
  PreViewDocumentApi,
  ViewEditApi,
  SignDocumentApi,
  FirstSaveDocumentApi,
  DeleteTempDocumentApi,
} from "./DocumentsSagaWorkers";

export function* DocumentLists() {
  yield takeLatest(getDocumentslist.type, DocumentListApi);
}
export function* InsertDocuments() {
  yield takeLatest(getInsertDocumentslist.type, InsertDocumentsApi);
}
export function* UpdateDocument() {
  yield takeLatest(getUpdateDocumentslist.type, UpdateDocumentApi);
}
export function* DeleteDocument() {
  yield takeLatest(getDeleteDocumentslist.type, DeleteDocumentApi);
}
export function* DownloadDocument() {
  yield takeLatest(getPreviewDownload.type, DownloadDocumentApi);
}
export function* PreViewDocument() {
  yield takeLatest(getPreviewDocument.type, PreViewDocumentApi);
}
export function* ViewEditDocument() {
  yield takeLatest(getViewEditDocument.type, ViewEditApi);
}
export function* SignDocument() {
  yield takeLatest(getsavesignature.type, SignDocumentApi);
}
export function* FirstSaveDocument() {
  yield takeLatest(getDocFirstSave.type, FirstSaveDocumentApi);
}

export function* DeleteTempDocument() {
  yield takeLatest(getDeleteTempDoc.type, DeleteTempDocumentApi);
}
