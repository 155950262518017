import { createSlice } from "@reduxjs/toolkit";
export const InsuranceSlice = createSlice({
  name: "Insurance",
  initialState: {
    insuranceDetails: [],
    InsuranceRes: "",
  },
  reducers: {
    postSingleInsDetail: (data) => {
      return data;
    },
    SetSingleInsDetail: (state, action) => {
      state.insuranceDetails = action.payload;
    },
    GetSingleInsDetail: (data) => {
      return data;
    },
    InsuranceResponse: (state, action) => {
      state.InsuranceRes = action.payload;
    },
  },
});

export const {
  postSingleInsDetail,
  SetSingleInsDetail,
  GetSingleInsDetail,
  InsuranceResponse,
} = InsuranceSlice.actions;

export default InsuranceSlice.reducer;
