import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "westside");

export const PatGenderDetails_Api = async () =>
  await axiosInst.get(`/westsideportal/list?list_name=sex`);

export const PatMaritalStatus_Api = async () =>
  await axiosInst.get(`/westsideportal/list?list_name=marital`);

export const PatLangDetails_Api = async () =>
  await axiosInst.get(`/westsideportal/list?list_name=language`);

export const PatRaceDetails_Api = async () =>
  await axiosInst.get(`/westsideportal/list?list_name=ethrace`);

export const PatientProfileUpdate_Api = async (dataArray) =>
  await axiosInst.post(
    `westsideportal/patientlogo/update?pid=${Pat_ID}`,
    dataArray
  );
export const InsertEventType_Api = async (File_data) =>
  await axiosInst.post(
    `/westsideportal/CalendarEvent/Insert?pid=${Pat_ID}`,
    File_data
  );

export const GetEventType_Api = async (data) =>
  await axiosInst.get(`/westsideportal/CalendarEvent/get?pid=${Pat_ID}`);

export const PatientProfileRemove_Api = async () =>
  await axiosInst.delete(`/westsideportal/patient/profileremove?pid=${Pat_ID}`);

export const PatientDetailUpdate_Api = async (payload) =>
  await axiosInst.put(
    `/westsideportal/patient/pendinginsert?pid=${Pat_ID}`,
    payload
  );

export const EmailExist_Api = async (data) =>
  await axiosInst.get(
    `/vozoportal/patientemailcheck?pid=${Pat_ID}&search=` + data
  );

export const ProfileUpdateStatus_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/westsideportal/UpdateProfileStatus?pid=${Pat_ID}`,
    Doc_Data
  );
export const State_Api = async () =>
  await axiosInst.get(`/westsideportal/list?list_name=state`);
export const Relation_Api = async () =>
  await axiosInst.get(`/westsideportal/list?list_name=personal_relationship`);

export const PatientSearch_Api = async (data) =>
  await axiosInst.post(`/westsideportal/patient/patsearch?search=${data}`);
