import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetReEmailRes } from "../../../StateManagement/Reducers/PreLoginState";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Description,
  Span,
} from "../../StyledComponents";
import {
  Container,
  Input,
  TitleImage,
  Title,
  ImageContainer,
  Atag,
} from "../StyledComponents";
import LoginLeft from "../LoginLeft";
import Logo from "../../../Assets/images/Logo.png";
import ResetImage from "../../../Assets/images/reset.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const emailResponse = useSelector((state) => state.PreLogin.ReEmailResp);

  const HandleDispatch = (e) => {
    e.preventDefault();
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    if (email !== "") {
      dispatch(GetReEmailRes({ type: { email: email } }));
      setModalAlerShow(true);
      setStatus("success");
      setModalMsg("Your details are being verified.");
    } else {
      setMailErr(true);
      setErrMsg("Please enter the Email address");
    }
  };

  const HandleNavigate = (e) => {
    e.preventDefault();
    Navigate("/Login");
  };

  const EmailCheck = (e) => {
    setemail(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailErr(false);
    } else {
      setMailErr(true);
      setErrMsg("Invalid Email");
    }
  };

  useEffect(() => {
    if (emailResponse.status === "Success") {
      setModalAlerShow(false);

      Navigate("/ReVerifyAccount", {
        state: { type: { email }, reset: true },
      });
    } else if (emailResponse.status === "Failure") {
      setModalAlerShow(false);

      setMailErr(true);
      setErrMsg("Please enter Registered email");
    }
  }, [emailResponse]);

  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" backColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backColor="#FFFFFF">
        <Div width="100%" display="flex">
          <Div width="475px" textAlign="center" margin="auto">
            <ImageContainer marginTop="15px" marginBottom="15px">
              <Image loading="lazy" src={Logo} height="70px" width="100px" />
            </ImageContainer>
            <TitleImage
              src={ResetImage}
              alt="Email Verified"
              width="124px"
              height="124px"
              marginTop="5%"
            ></TitleImage>
            <Title
              color="#000000"
              fontWeight="800"
              fontSize="32px"
              lineHeight="44px"
              marginBottom="12px"
            >
              Forget Password
            </Title>
            <Description
              fontSize="20px"
              lineHeight="27px"
              alignItems="center"
              color=" #667085"
              marginBottom="32px"
            >
              Provide your email &amp; we’ll be sending you a verification code
              to reset your password.
            </Description>
            <Form>
              <FormGroup marginBottom="41px" display="grid" width="100%">
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  fontSize="14px"
                  color="#7D8592"
                  fontWeight="600"
                  lineHeight="24px"
                >
                  Email ID
                </FormLabel>
                <Input
                  color="#7D8592"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => EmailCheck(e)}
                />
                {mailErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily="inter"
                  >
                    {errMsg}
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup marginBottom="32px" display="grid" width="100%">
                <Button
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  className="save_active"
                  padding="18px 140px 18px 140px"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  letterSpacing="1.1px"
                  disabled={btndis}
                  onClick={(e) => HandleDispatch(e)}
                >
                  Send Verification Code
                </Button>
              </FormGroup>

              <Span>Back to</Span>
              <Atag
                color="#1F28EB"
                borderBottom="1px solid #1F28EB"
                margin="0px 0px 0px 10px"
                onClick={(e) => HandleNavigate(e)}
              >
                Login
              </Atag>
            </Form>
          </Div>
        </Div>
      </Div>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Container>
  );
};

export default ForgetPassword;
