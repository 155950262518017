import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetUpcomingAppointment,
  changeAppointmentData,
  clearAppointmentData,
} from "../../../StateManagement/Reducers/AppointmentsState";

import AppointmentCancel from "../../Appointment/AppointmentCancel";
import NoDataImage from "./NoDataImage";

import { PageSubTitle, Button, Text, Image } from "../../StyledComponents";
import Loading from "../../StyledComponents/Loading";

import { Card, Comdisp, Hrline } from "./styles";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiLocationPlus } from "react-icons/bi";
import { FiClock } from "react-icons/fi";

import NoAppt from "../../../Assets/images/no_appt_image.png";
import Rectangle from "../../../Assets/images/Rectangle.png";

const Nextappt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [apptCancel, setApptcancel] = useState(false);

  const NextAppointment = useSelector(
    (state) => state.Appointments.lastAppointment
  );
  const Refresh = useSelector((state) => state.Appointments.RefreshAppt);
  const loading = useSelector((state) => state.Appointments.Loading);

  const navigatePage = () => {
    navigate("/appointment/add_appointment");
    dispatch(clearAppointmentData(""));
  };

  useEffect(() => {
    dispatch(GetUpcomingAppointment("remove"));
  }, [dispatch, Refresh]);

  return (
    <>
      <Card
        background="#ffffff"
        border="1px solid #d8e0f0"
        borderradius="8px"
        padding="20px"
        margintop="32px"
        width="100%"
      >
        {!loading ? (
          <>
            {NextAppointment.length !== 0 ? (
              <>
                <PageSubTitle fontweight="600" lineHeight="33px" media>
                  New Appointment
                </PageSubTitle>
                {NextAppointment.map((details) => (
                  <>
                    <PageSubTitle
                      clr="#407bff"
                      marginTop="14px"
                      fontweight="600"
                      lineHeight="33px"
                      marginBottom="14px"
                    >
                      {details.pc_eventDate}
                    </PageSubTitle>

                    <Comdisp disp="flex" marginBottom="7px">
                      <FiClock className="clock" />
                      <Text
                        color="#7d8592"
                        fontWeight="500"
                        textAlign="left"
                        mt="0px"
                      >
                        {details.duration}
                      </Text>
                    </Comdisp>

                    <Comdisp marginLeft="-2px" disp="flex" marginTop="17px">
                      <BiLocationPlus className="locate" />
                      <Text
                        color="#7D8592"
                        fontSize="14px"
                        fontWeight="600"
                        textAlign="left"
                        mt="0px"
                        mb="4px"
                      >
                        {details.facility_name}
                      </Text>
                    </Comdisp>

                    <Text
                      color="#7D8592"
                      marginLeft="30px"
                      fontSize="14px"
                      fontWeight="400"
                      textAlign="left"
                      mt="0px"
                      mb="-6px"
                      height="43px"
                      width="96%"
                      className="scrollbar_adujust"
                    >
                      {details.street}, {details.city}, {details.state},
                      {details.postal_code}
                    </Text>

                    <Comdisp
                      disp="flex"
                      alignItem="center"
                      JustifyContent="flex-start"
                    >
                      <Image
                        loading="lazy"
                        src={Rectangle}
                        height="32px"
                        width="32px"
                        borderRadius="50%"
                      />
                      <Comdisp disp="column" marginTop="0px" textAlign="left">
                        <Text
                          color="#263446"
                          paddingLeft="8px"
                          fontSize="16px"
                          fontWeight="500"
                          textAlign="left"
                          mt="0px"
                          mb="0px"
                        >
                          {details.provider_name}
                        </Text>
                        <Text
                          paddingLeft="8px"
                          paddingTop="2px"
                          color="#7D8592"
                          fontSize="14px"
                          fontWeight="400"
                          textAlign="left"
                          mt="0px"
                          mb="0px"
                        >
                          {details.specialty}
                        </Text>
                      </Comdisp>
                    </Comdisp>
                    <Hrline mb="14px" mt="13px" />
                    <Button
                      background="none"
                      display="flex"
                      marginLeft="auto"
                      marginRight="auto"
                      alignItem="center"
                      JustifyContent="center"
                      marginBottom="-6px"
                      onClick={() => {
                        setApptcancel(true);
                        dispatch(
                          changeAppointmentData({
                            name: "cancelID",
                            value: details.pc_eid,
                          })
                        );
                      }}
                    >
                      <AiOutlineCloseCircle fill="#F97066" font-size="1.5em" />
                      <Text marginLeft="10px" color="#f97066" mt="0px" mb="0px">
                        Cancel Booking
                      </Text>
                    </Button>
                  </>
                ))}
              </>
            ) : (
              <>
                <PageSubTitle media>Schedule Appointment</PageSubTitle>
                <NoDataImage
                  image={NoAppt}
                  height="168.68px"
                  imageHeight="158.68px"
                  imageWidth="229px"
                  margin="22px 0px 0px 0px"
                />
                <Hrline mt="19.5px" mb="14px" />

                <Button
                  marginLeft="auto"
                  marginRight="auto"
                  background="none"
                  display="flex"
                  onClick={() => navigatePage()}
                >
                  <Text
                    fontSize="16px"
                    clr="#407BFF"
                    mt="0px"
                    mb="0px"
                    lineHeight="20px"
                    color="#407BFF"
                    fontWeight="500"
                    letterSpacing="0.0025em"
                    id="dbrd_sch-p_mkApt"
                  >
                    Make Appointment
                  </Text>
                </Button>
              </>
            )}
          </>
        ) : (
          <Loading height="40vh" />
        )}
      </Card>
      <AppointmentCancel
        show={apptCancel}
        HidePopup={() => setApptcancel(false)}
      />
    </>
  );
};

export default Nextappt;
