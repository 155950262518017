import { all } from "redux-saga/effects";

import {
  LoginEmailCheck,
  LoginPassWordSet,
  PasswordResetEmailCheck,
  SignUp,
  VzPortLogCheck,
  GetProviderStatus,
} from "./PreLogin/PreLoginWatchers";
import {
  DocumentLists,
  InsertDocuments,
  UpdateDocument,
  DeleteDocument,
  DownloadDocument,
  PreViewDocument,
  ViewEditDocument,
  SignDocument,
  FirstSaveDocument,
  DeleteTempDocument,
} from "./Documents/DocumentsSagaWatchers";
import { LoginSaga } from "./Login/LoginSagaWatchers";
import {
  upcomingAppointmentsSaga,
  getProviderAvailablitySaga,
  EditAppointmentSaga,
  GetProviderSaga,
  GetFacilitySaga,
  SaveAppointmentSaga,
  CancelApptSaga,
  PastApptSaga,
  getPortalAppAvailablitySaga,
  getOnsiteAppReqSaga,
} from "./Appointments/AppointmentsSagaWatchers";
import {
  HomeWorkSaga,
  UpdateWatchedSaga,
} from "./HomeWork/HomeWorkSagaWatchers";
import {
  PatGenderDetails,
  InsertEventType,
  GetEventType,
  PatMaritalStatus,
  PatLangDetails,
  PatRaceDetails,
  PatientProfileUpdate,
  PatientProfileRemove,
  PatientDetailUpdate,
  EmailExist,
  PatientStatus,
  Relationships,
  SearchPatient,
  ProfileUpdateStatus,
} from "./Settings/SettingsSagaWatchers";

import {
  UserDetails,
  NotificationDetails,
  NotificationUpdate,
  NotificationDelete,
} from "./TopNavbar/TopNavbarSagaWatchers";
import {
  MessageSendSaga,
  MessagerListSaga,
  PinchatSaga,
  PreviewDownloadSagaM,
  ProviderListSaga,
  UnreadCountUpdateSaga,
} from "./Messenger/MessengerSagaWatchers";
import {
  SingleInsuranceDetail,
  GetInsuranceDetail,
} from "./Insurance/InsuranceSagaWatchers";
import { ListSaga } from "./HealthProfile/HealthProfileSagaWatchers";
export default function* rootSaga() {
  return yield all([
    LoginEmailCheck(),
    LoginPassWordSet(),
    PasswordResetEmailCheck(),
    SignUp(),
    VzPortLogCheck(),
    UserDetails(),
    NotificationDetails(),
    NotificationUpdate(),
    NotificationDelete(),
    LoginSaga(),
    GetProviderStatus(),
    // Appointments//
    PastApptSaga(),
    upcomingAppointmentsSaga(),
    CancelApptSaga(),
    getProviderAvailablitySaga(),
    EditAppointmentSaga(),
    GetProviderSaga(),
    GetFacilitySaga(),
    SaveAppointmentSaga(),
    getPortalAppAvailablitySaga(),
    getOnsiteAppReqSaga(),
    // Appointments//
    // Documents //
    DocumentLists(),
    InsertDocuments(),
    UpdateDocument(),
    DeleteDocument(),
    DownloadDocument(),
    PreViewDocument(),
    ViewEditDocument(),
    SignDocument(),
    FirstSaveDocument(),
    DeleteTempDocument(),

    // Documents //
    // Health Profile //
    ListSaga(),

    // Health Profile //

    // Home Work //
    HomeWorkSaga(),
    UpdateWatchedSaga(),
    // Home Work //
    //Insurance
    SingleInsuranceDetail(),
    GetInsuranceDetail(),
    //Insurance
    // Settings //
    PatGenderDetails(),
    InsertEventType(),
    GetEventType(),
    PatMaritalStatus(),
    PatLangDetails(),
    PatRaceDetails(),
    PatientProfileUpdate(),
    PatientProfileRemove(),
    PatientDetailUpdate(),
    EmailExist(),
    PatientStatus(),
    Relationships(),
    SearchPatient(),
    ProfileUpdateStatus(),
    // Settings //

    // Message //
    ProviderListSaga(),
    MessagerListSaga(),
    MessageSendSaga(),
    PreviewDownloadSagaM(),
    UnreadCountUpdateSaga(),
    GetProviderStatus(),
    PinchatSaga(),
    // Message //
  ]);
}
