import styled from "styled-components";
import { FlexCenter, SpanCont } from "../StyledComponents";

export const PatientInfo = styled.div`
  font-weight: 700;
  font-size: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const LightText = styled.span`
  font-weight: 200 !important;
`;

export const CircleOverImage = styled(FlexCenter)`
  border-radius: 50%;
  height: 58px;
  width: 58px;
  margin-right: 16px;
  border: 1.5px solid #e9eef9;
  position: relative;

  & .blue-curve {
    position: absolute;
    top: -1.5px;
    right: -1.5px;
  }
`;

export const SubContainer = styled.div``;

export const Div_1 = styled.div`
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.JustifyContent && p.JustifyContent};
  flex: ${(p) => p.flex && p.flex};
  height: ${(p) => p.height && p.height};
  ${(p) =>
    p.gridTop &&
    `
    @media only screen and (min-width: 2560px) {
      display : grid;
      grid-template-areas:
      "Appointment Document Document"
      "Group Group Group"
 ;
      grid-template-columns: 1fr 1fr 1fr;
      //grid-template-rows:385px 353px;
      column-gap: 16px;
      row-gap:16px;

      }
      @media only screen and (max-width: 2560px) {
        display : grid;
        grid-template-areas:
        "Appointment Document Document"
        "Group Group Group"
   ;
        grid-template-columns: 1fr 1fr 1fr;
        //grid-template-rows:353px 353px;
        column-gap: 16px;
        row-gap:16px;

        }
      @media only screen and (max-width: 1440px) {
        display : grid;
        grid-template-areas:
        "Appointment Document Document"
        "Group Group Group"
   ;
        grid-template-columns: 1fr 1fr 1fr;
        //grid-template-rows:346px 353px;
        column-gap: 16px;
        row-gap:16px;
        }
        @media only screen and (max-width: 1280px) {
          display : grid;
          grid-template-areas:
          "Appointment Document"
          "Group Group"
          ;
          grid-template-columns: 1fr 1fr ;
          //grid-template-rows:385px 353px 353px;
          column-gap: 16px;
          row-gap:16px;

          }
          @media only screen and (max-width: 950px) {
            display : grid;
            grid-template-areas:
            "Appointment"
            "Document"
            "Group";
            grid-template-columns: 1fr;
            //grid-template-rows:350px 350px 400px ;
            column-gap: 38px;
            row-gap:16px;
  
            }
    `}
`;

export const CurveImg = styled.img`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const SpanContAdj = styled(SpanCont)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 125px;
  display: inline-block;
`;
