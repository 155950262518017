import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Success from "../../Modules/StyledComponents/Toastify/Success";
import Error from "../../Modules/StyledComponents/Toastify/Error";

export const TopNavbarSlice = createSlice({
  name: "TopNav",
  initialState: {
    UserLoading: false,
    userDetails: {},
  },
  reducers: {
    GetUserDetails: (state) => {
      state.UserLoading = true;
    },
    setGetUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.UserLoading = false;
    },
    GetNotificationDetails: (Data) => {
      return Data;
    },
    SetNotificationDetails: (state, action) => {
      state.NotificationDetails = action.payload;
    },
    GetNotificationUpdate: (Data) => {
      return Data;
    },
    SetNotificationUpdate: (state, action) => {
      state.NotificationUpdate = action.payload;
    },
    GetNotificationDelete: (Data) => {
      return Data;
    },
    SetNotificationDelete: (state, action) => {
      if (isNaN(action.payload)) {
        toast(<Error msg={action.payload} />, {
          containerId: "B",
          className: "error_badge",
        });
      } else {
        let message = "Notifications Deleted Successfully";
        toast(<Success msg={message} />, {
          containerId: "A",
          // progressClassName: "Progressbg",
        });
      }
    },
    GetNotificationlab: (state) => {
      state.Notificationlab = "Lab Results";
    },
    GetNotificationlabempty: (state) => {
      state.Notificationlab = "";
    },
  },
});

export const {
  GetUserDetails,
  GetNotificationDetails,
  GetNotificationUpdate,
  setGetUserDetails,
  GetNotificationDelete,
  GetNotificationlab,
  SetNotificationDetails,
  SetNotificationUpdate,
  SetNotificationDelete,
  GetNotificationlabempty,
} = TopNavbarSlice.actions;

export default TopNavbarSlice.reducer;
