import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "westside");

export const UserDetails_Api = async () =>
  await axiosInst.get(`/westsideportal/PatientData?pid=${Pat_ID}`);

export const NotificationDetails_Api = async () =>
  await axiosInst.get(`/westside/notification/data?pid=${Pat_ID}`);

export const NotificationUpdate_Api = async (data) =>
  await axiosInst.post(`/westside/notification/update`, data);

export const NotificationDelete_Api = async (data) =>
  await axiosInst.post(`/westside/notification/delete`, data);
