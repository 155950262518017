import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";
import axios from "axios";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "westside");

export const DocumentList_Api = async (Doc_type) =>
  await axiosInst.post(
    `/westsideportal/getDocumentData?pid=${Pat_ID}`,
    Doc_type
  );

export const InsertDocuments_Api = async (File_data) =>
  await axiosInst.post(
    `/westsideportal/InsertDocumentData?pid=${Pat_ID}`,
    File_data
  );

export const UpdateDocument_Api = async (row_id) =>
  await axiosInst.put(
    `/westsideportal/UpdateDocumentData?pid=${Pat_ID}`,
    row_id
  );

export const DeleteDocument_Api = async (Data) =>
  await axiosInst.put(`/westsideportal/DeleteDocumentData?pid=${Pat_ID}`, Data);

export const DownloadDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/westsideportal/PreviewDownload?pid=${Pat_ID}`,
    Doc_Data
  );

export const PreViewDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/westsideportal/DownloadDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const ViewEdit_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/westsideportal/ViewEditDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const SignDocument_Api = async (Doc_Data) =>
  await axiosInst.post(
    `/westsideportal/SaveSignatureData?pid=${Pat_ID}`,
    Doc_Data
  );

export const FirstSaveDocument_Api = async (Doc_Data) =>
  await axiosInst.post(
    `/westsideportal/FirstSaveDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const DeleteTempDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/westsideportal/DeleteTempDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );
