import React from "react";
import {
  LoaderContainer,
  LoaderSubContainer,
  Spinner,
  LoaderImage,
} from "./styles";

import Loader from "../../../Assets/images/Logo.png";

const Loading = ({ height }) => {
  return (
    <LoaderContainer height={height}>
      <LoaderSubContainer>
        <Spinner blocker />
        <Spinner one />
        <Spinner two />
        <Spinner three />
      </LoaderSubContainer>
      <LoaderImage src={Loader} />
    </LoaderContainer>
  );
};

export default Loading;
