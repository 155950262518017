import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GetPatientUrl } from "../../../StateManagement/Reducers/PreLoginState";
import PortalAccess from "../../../Assets/images/portal_access.png";
import { BodyContainer, Button } from "../../StyledComponents";
import { ImageContainer, Image } from "../StyledComponents";
import Logo from "../../../Assets/images/Logo.png";

const AccessDenied = () => {
  const dispatch = useDispatch();
  const PatientUrl = useSelector((state) => state.PreLogin.PatientUrl);

  useEffect(() => {
    GetPatientUrl();
  }, [dispatch]);

  return (
    <BodyContainer textAlign="center" Flex="1" height="100%">
      <ImageContainer
        paddingTop="20px"
        marginBottom="15px"
        textAlign="left"
        marginLeft="50px"
      >
        <Image loading="lazy" src={Logo} height="70px" width="100px" />
      </ImageContainer>

      <Image src={PortalAccess} display="block" width="52%" height="62%" />
      <Button
        background="#407BFF"
        display="block"
        height="50px"
        fontFamily="'Inter',sans-serif"
        fontSize="16px"
        fontWeight="500"
        lineHeight="26px"
        padding="12px 10%"
        borderRadius="8px"
        marginLeft="auto"
        marginRight="auto"
        marginTop="46px"
        cursor="unset"
      >
        You dont have access to {PatientUrl}. Please contact your provider for
        details
      </Button>
    </BodyContainer>
  );
};

export default AccessDenied;
