import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import {
  setGetUserDetails,
  SetNotificationDetails,
  SetNotificationUpdate,
  SetNotificationDelete,
} from "../../StateManagement/Reducers/TopNavbarState";

import {
  UserDetails_Api,
  NotificationDetails_Api,
  NotificationUpdate_Api,
  NotificationDelete_Api,
} from "../Apis/TopNavbarApi";

export function* UserDetailsApi() {
  try {
    const res = yield call(UserDetails_Api);
    const data = Decrypt_Value(res.data, "westside");
    if (res.status === 200) {
      yield put(setGetUserDetails(data));

      localStorage.setItem(
        "account_type",
        Encrypt_Value(Number(data.account_type), "westside")
      );

      localStorage.setItem(
        "account_update",
        Encrypt_Value(Number(data.account_update), "westside")
      );

      localStorage.setItem(
        "trial_period",
        Encrypt_Value(
          String(data.trial_period).toLowerCase() === "true",
          "westside"
        )
      );
    }
  } catch (e) {
    yield put(setGetUserDetails([]));
    console.log(e.message);
  }
}
export function* NotificationDetailsApi() {
  try {
    const res = yield call(NotificationDetails_Api);
    const data = Decrypt_Value(res.data, "westside");
    if (res.status === 200) {
      yield put(SetNotificationDetails(data));
    }
  } catch (e) {
    yield put(SetNotificationDetails([]));
    console.log(e.message);
  }
}
export function* NotificationUpdateApi({ payload }) {
  const Data = Encrypt_Value(payload, "westside");
  try {
    const res = yield call(NotificationUpdate_Api, Data);
    let data = "";
    if (res.data !== 0 && res.data !== "") {
      data = Decrypt_Value(res.data, "westside");
    } else {
      data = res.data;
    }
    if (res.status === 200) {
      yield put(SetNotificationUpdate(data));
    }
  } catch (e) {
    yield put(SetNotificationUpdate([]));
    console.log(e.message);
  }
}
export function* NotificationDeleteApi({ payload }) {
  const Data = Encrypt_Value(payload, "westside");
  try {
    const res = yield call(NotificationDelete_Api, Data);

    if (res.status === 200) {
      const res = yield call(NotificationDetails_Api);
      yield put(SetNotificationDelete(1));
    }
  } catch (e) {
    yield put(SetNotificationDelete([]));
    console.log(e.message);
  }
}
