import axios from "axios";

const finalloginurl = process.env.REACT_APP_BACKEND_URL;

const baseURL = finalloginurl + "default/api";
let headers = {};
let access = localStorage.accesstoken ? localStorage.accesstoken : "";

if (access.length > 0) {
  headers.Authorization = `${access}`;
} else {
  headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
}

const axiosInst = axios.create({
  baseURL,
  headers,
});
axiosInst.interceptors.request.use(
  (config) => {
    if (localStorage.accesstoken) {
      config.headers.Authorization = localStorage.accesstoken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInst;
