import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

import {
  getProvidersList,
  changeAppointmentData,
  getFacilityList,
  addAppointment,
  changeLoadingState,
  changeErrorObject,
  AppointmentEdited,
  clearInput,
  // GetBillingFacility,
  appointmentSaved,
} from "../../../StateManagement/Reducers/AppointmentsState";

import SelectedProvider from "./SelectedProvider";
import SelectedOffice from "./SelectedOffice";
import TimeDateSelector from "./TimeDateSelector";

import { BsSearch } from "react-icons/bs";
import {
  Div,
  Text,
  NormalImage,
  Button,
  SpanCont,
} from "../../StyledComponents";
import {
  InputInlineText,
  ReasonInput,
  DropdownDiv,
  AppointmentInput,
  DropdownText,
  AppointmentInput2,
  AppointmentInput3,
  TimeDateDiv,
  DivVariant2,
  HiddenButton,
  InitialsText,
  Text1,
  ChangeText,
} from "./Styles";

import dateIcon from "../../../Assets/images/calendar_icon.png";
import providerIcon from "../../../Assets/images/provider_icon.png";

import clockLogo from "../../../Assets/images/clock_logo.png";
import officeIcon from "../../../Assets/images/office_icon.png";
import officeLogo from "../../../Assets/images/office_logo.png";
import providerLogo from "../../../Assets/images/pro_icon1.png";

import reasonIcon from "../../../Assets/images/reason_icon.png";

const AddAppointment = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const providerRef = useRef(null);
  const officeRef = useRef(null);
  const specialityRef = useRef(null);
  const appointmentRef = useRef(null);
  const timeRef = useRef(null);
  const timeDivRef = useRef(null);
  const timeSelectRef = useRef(null);
  const dropRef1 = useRef(null);
  const dropRef2 = useRef(null);
  const dropRef3 = useRef(null);
  const dropRef4 = useRef(null);

  const [providerError, setProviderError] = useState(false);
  const [specialityError, setSpecialityError] = useState(false);
  const [appointmentError, setAppointmentError] = useState(false);
  const [officeError, setOfficeError] = useState(false);
  const [providerDrop, setProviderDrop] = useState(false);
  const [officeDrop, setOfficeDrop] = useState(false);
  const [searchProvider, setSearchProvider] = useState("");
  const [searchOffice, setSearchOffice] = useState("");
  const [searchSpeciality, setSearchSpeciality] = useState("");
  const [searchAppointment, setSearchAppointment] = useState("");
  const [imageError, setImageError] = useState([]);
  const [savebtndisabled, setsavebtndisabled] = useState(false);
  const [specialityDrop, setSpecialityDrop] = useState(false);
  const [appointmentDrop, setAppointmentDrop] = useState(false);

  const bill_facility_id = useSelector(
    (state) => state.Appointments.BillingFacility
  );
  const providersList = useSelector(
    (state) => state.Appointments.providersList
  );
  const facilityList = useSelector((state) => state.Appointments.facilityList);
  const providerLoading = useSelector(
    (state) => state.Appointments.loadingProviders
  );
  const officeLoading = useSelector(
    (state) => state.Appointments.loadingOffice
  );
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );

  const timeError = useSelector((state) => state.Appointments.timeError);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  const proImageUrl = backurl + "/sites/default/documentimg/";
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "westside");

  // ------------------------------ State and Variables End--------------------------------------------------- //

  // ------------------------------ Functions Start------------------------------------------------------------- //

  const convertHMS = (value) => {
    return Math.floor(value * 60);
  };

  const selectProvider = (row) => {
    dispatch(changeAppointmentData({ name: "select_provider", value: row }));
    setProviderError(false);
    setProviderDrop(false);
  };

  const selectOffice = (row) => {
    dispatch(changeAppointmentData({ name: "select_office", value: row }));
    setOfficeError(false);
    setOfficeDrop(false);
  };

  const handleClick1 = (e) => {
    if (
      providerRef.current &&
      !providerRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchProvider("");
    }
  };

  const handleClick2 = (e) => {
    if (
      officeRef.current &&
      !officeRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchOffice("");
    }
  };

  const handleClick3 = (e) => {
    if (
      timeDivRef.current &&
      timeSelectRef.current &&
      !timeDivRef.current.contains(e.target) &&
      !timeSelectRef.current.contains(e.target)
    ) {
      dispatch(changeAppointmentData({ name: "showSelector", value: true }));
    }
  };

  const handleClick4 = (e) => {
    if (
      dropRef1.current &&
      providerRef.current &&
      !dropRef1.current.contains(e.target) &&
      !providerRef.current.contains(e.target)
    ) {
      setProviderDrop(false);
    }
  };

  const handleClick5 = (e) => {
    if (
      dropRef2.current &&
      officeRef.current &&
      !dropRef2.current.contains(e.target) &&
      !officeRef.current.contains(e.target)
    ) {
      setOfficeDrop(false);
    }
  };
  const handleClick6 = (e) => {
    if (
      specialityRef.current &&
      !specialityRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchSpeciality("");
    }
  };
  const handleClick7 = (e) => {
    if (
      dropRef3.current &&
      specialityRef.current &&
      !dropRef3.current.contains(e.target) &&
      !specialityRef.current.contains(e.target)
    ) {
      setSpecialityDrop(false);
    }
  };
  const handleClick8 = (e) => {
    if (
      appointmentRef.current &&
      !appointmentRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchAppointment("");
    }
  };
  const handleClick9 = (e) => {
    if (
      dropRef4.current &&
      appointmentRef.current &&
      !dropRef4.current.contains(e.target) &&
      !appointmentRef.current.contains(e.target)
    ) {
      setAppointmentDrop(false);
    }
  };
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getInitials = (x) => {
    let a = x.split(" ");
    let b = a[0].substr(0, 1).toUpperCase();
    return b;
  };

  const errorImage = (id) => {
    setImageError([id, ...imageError]);
  };
  const saveAppointment = () => {
    setsavebtndisabled(true);
    setTimeout(() => {
      setsavebtndisabled(false);
    }, 1200);

    // if (bill_facility_id === undefined) {
    //   dispatch(
    //     appointmentSaved({
    //       color: "danger",
    //       message:
    //         "unable to book appointments as there is no billing facility provided.\nContact provider for more information",
    //     })
    //   );
    //   return;
    // }
    var err = 0;
    if (appointmentData["provider"] === "") {
      setProviderError(true);
      err = 2;
    }
    if (appointmentData["office"] === "") {
      setOfficeError(true);
      err = 2;
    }

    if (appointmentData["apttime"] === "") {
      dispatch(changeErrorObject({ name: "noTime", value: true }));
      err = 2;
    }

    if (err > 0) {
      return false;
    } else {
      var timenew =
        appointmentData["aptdate"] + " " + appointmentData["apttime"];

      var timedetnew = moment(timenew, "YYYY-MM-DD hh:mm a");
      var durationnew = 15;

      var categoryId = 2;
      var pc_title = "New Patient";
      var pc_apptstatus = "EMAIL";

      var alldaynew = false;
      var walkinnew = false;
      var repeats = "";
      var select1 = 0;
      var select2 = 0;
      var untildate = "";
      var fromdate = appointmentData["aptdate"];
      var todate = appointmentData["aptdate"];
      var days_wise = false;

      var tempdatenew = moment(timedetnew, moment.defaultFormat).toDate();
      var startTimenew = moment(tempdatenew).format("hh:mm a");
      var returned_endatenew = moment(tempdatenew, "YYYY-MM-DD hh:mm a").add(
        durationnew,
        "minutes"
      );
      var startdatenew = moment(tempdatenew).format("YYYY-MM-DD");
      var secondsnew = convertHMS(durationnew);
      var endTimenew = moment(returned_endatenew).format("hh:mm a");
      var endDatenew = moment(returned_endatenew).format("YYYY-MM-DD");

      var article = {
        pc_pid: Pat_ID,
        pc_catid: categoryId,
        pc_aid: appointmentData["provider"],
        pc_title,
        pc_duration: secondsnew,
        pc_hometext: "",
        pc_apptstatus,
        pc_eventDate: startdatenew,
        pc_endDate: endDatenew,
        pc_startTime: startTimenew,
        pc_endTime: endTimenew,
        pc_facility: appointmentData["office"],
        pc_informant: appointmentData["provider"],
        pc_billing_location: "",
        pc_select1: select1,
        pc_select2: select2,
        pc_untildate: untildate,
        pc_fromdate: fromdate,
        pc_todate: todate,
        days_wise: days_wise,
        allday: alldaynew,
        repeats: repeats,
        walkin: walkinnew,
        encounter: appointmentData["encounter"],
        eid: appointmentData["eid"],
        patient_reason: appointmentData["reason"],
      };
      if (appointmentData["eid"] === 0) {
        dispatch(addAppointment(article));
      } else {
        dispatch(AppointmentEdited(article));
      }
    }
  };

  // ------------------------------ Functions End------------------------------------------------------------- //

  // ------------------------------ useEffect Start------------------------------------------------------------- //

  //provider search

  useEffect(() => {
    dispatch(getFacilityList({ pid: Pat_ID }));
    dispatch(
      getProvidersList({
        pid: Pat_ID,
        allprovider: 0,
      })
    );
    document.addEventListener("click", handleClick4);
    document.addEventListener("click", handleClick5);
    document.addEventListener("click", handleClick7);
    document.addEventListener("click", handleClick9);
    document.addEventListener("mouseup", handleClick1);
    document.addEventListener("mouseup", handleClick2);
    document.addEventListener("mouseup", handleClick3);
    document.addEventListener("mouseup", handleClick6);
    document.addEventListener("mouseup", handleClick8);

    return () => {
      document.removeEventListener("click", handleClick4);
      document.removeEventListener("click", handleClick5);
      document.removeEventListener("click", handleClick7);
      document.removeEventListener("click", handleClick9);
      document.removeEventListener("mouseup", handleClick1);
      document.removeEventListener("mouseup", handleClick2);
      document.removeEventListener("mouseup", handleClick3);
      document.removeEventListener("mouseup", handleClick6);
      document.removeEventListener("mouseup", handleClick8);
    };
  }, []);

  useEffect(() => {
    if (appointmentData["changeUrl"].length > 0) {
      navigation(`${appointmentData["changeUrl"]}`);
    }
  }, [navigation, appointmentData["changeUrl"]]);

  // useEffect(() => {
  //   dispatch(GetBillingFacility());
  // }, []);

  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <Div
      backColor="#f4f4f4"
      width="100%"
      height="100%"
      overflowY="auto"
      flexCenterHorizontal
    >
      <DivVariant2>
        <Text1 align="left" fontWeight="500" className="createfont">
          {appointmentData["eid"] !== 0
            ? "Edit Appointment"
            : "Create new appointment"}
        </Text1>

        <Div
          margin="20px 0px 0px 0px"
          flexCenterBetween
          height={appointmentData["office"] === "" ? "100px" : "auto"}
        >
          <Div flexCenterVertical>
            <NormalImage
              loading="lazy"
              src={officeIcon}
              height="13.33px"
              width="14.67px"
            />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Office
            </Text>
            {appointmentData["office"] !== "" && (
              <HiddenButton
                mediaWidth="1300px"
                color="#407BFF"
                fontSize="12px"
                marginLeft="12px"
                background="white"
                cursor="pointer"
                onClick={() => dispatch(clearInput("office"))}
              >
                Edit
              </HiddenButton>
            )}
          </Div>
          <Div width="55%" height="100%" position="relative">
            {/* {appointmentData["office"] !== "" ? ( */}
            <SelectedOffice Type="office" />
            {/* ) : ( */}
            {/* <>
                <AppointmentInput2
                  errorBorder={officeError}
                  name="office"
                  backgroundImage={officeLogo}
                  placeholder="Type a customer name"
                  ref={officeRef}
                  value={searchOffice}
                  onChange={(e) => {
                    setSearchOffice(e.target.value);
                  }}
                  dropOpen={officeDrop}
                  onClick={() => setOfficeDrop(true)}
                  onFocus={() => setOfficeDrop(true)}
                  id="aptLst_napt-inp_ofc"
                />
                <BsSearch id="inFocus1" />
                {officeError && (
                  <Text
                    color="#F65160"
                    margin="2px 0px 0px 0px"
                    fontSize="12px"
                    width="100%"
                    align="center"
                  >
                    Select an office
                  </Text>
                )}

                <InputInlineText onClick={() => officeRef.current.focus()}>
                  Select Office
                </InputInlineText>
                {officeDrop && (
                  <DropdownDiv
                    width="100%"
                    id="inFocus2"
                    ref={dropRef2}
                    onClick={() => setOfficeDrop(false)}
                  >
                    {officeLoading ? (
                      <DropdownText
                        width="100%"
                        border="none"
                        padding="0 16px"
                        flexCenterVertical
                      >
                        Loading...
                      </DropdownText>
                    ) : (
                      <>
                        {facilityList.length !== 0 ? (
                          <>
                            {facilityList.map((row) => (
                              <DropdownText
                                width="100%"
                                border="none"
                                height="50px"
                                flexCenterVertical
                                name="office"
                                paddingLeft="16px"
                                key={row.id}
                                onClick={(e) => selectOffice(row)}
                              >
                                {row.name}
                              </DropdownText>
                            ))}
                          </>
                        ) : (
                          <DropdownText
                            width="100%"
                            border="none"
                            flexCenterVertical
                            padding="0 16px"
                          >
                            No Results Found
                          </DropdownText>
                        )}
                      </>
                    )}
                  </DropdownDiv>
                )}
              </> */}
            {/* )} */}
          </Div>
        </Div>

        {/* Provider Start */}

        <Div
          margin="20px 0 0"
          flexCenterBetween
          height={appointmentData["provider"] === "" ? "100px" : "auto"}
        >
          <Div flexCenterVertical>
            <NormalImage
              loading="lazy"
              src={providerIcon}
              width="14.67px"
              height="15.2px"
            />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Provider
            </Text>
            {appointmentData["provider"] !== "" && (
              <HiddenButton
                mediaWidth="1100px"
                color="#407BFF"
                fontSize="12px"
                marginLeft="12px"
                background="white"
                cursor="pointer"
                onClick={() => {
                  dispatch(clearInput("provider"));
                }}
              >
                Edit
              </HiddenButton>
            )}
          </Div>
          <Div width="55%" height="100%" position="relative">
            {/* {appointmentData["provider"] !== "" ? ( */}
            <SelectedProvider />
            {/* ) : (
              <>
                <AppointmentInput
                  errorBorder={providerError}
                  name="provider"
                  placeholder="Search Provider"
                  value={searchProvider}
                  onChange={(e) => {
                    setSearchProvider(e.target.value);
                  }}
                  backgroundImage={providerLogo}
                  ref={providerRef}
                  dropOpen={providerDrop}
                  onClick={() => setProviderDrop(true)}
                  onFocus={() => setProviderDrop(true)}
                  id="aptLst_napt-inp_prdr"
                />
                <BsSearch id="inFocus1" />
                {providerError && (
                  <Text
                    color="#F65160"
                    margin="2px 0px 0px 0px"
                    fontSize="12px"
                    width="100%"
                    align="center"
                  >
                    Select a provider
                  </Text>
                )}
                <InputInlineText onClick={() => providerRef.current.focus()}>
                  Select Provider
                </InputInlineText>
                {providerDrop && (
                  <DropdownDiv
                    width="100%"
                    padding="8px 0px"
                    id="inFocus2"
                    ref={dropRef1}
                  >
                    {providerLoading ? (
                      <DropdownText
                        width="100%"
                        border="none"
                        padding="0 16px"
                        flexCenterVertical
                      >
                        Loading...
                      </DropdownText>
                    ) : (
                      <>
                        {providersList.length !== 0 &&
                        appointmentData["speciality"] !== "" ? (
                          <>
                            {providersList.map((row) => (
                              <DropdownText
                                width="100%"
                                border="none"
                                flexCenterVertical
                                height="32px"
                                name="provider"
                                key={row.id}
                                onClick={(e) => selectProvider(row)}
                              >
                                {row.img_url &&
                                row.img_url.length > 0 &&
                                !imageError.includes(row.id) ? (
                                  <NormalImage
                                    loading="lazy"
                                    height="28px"
                                    width="28px"
                                    borderRadius="50%"
                                    margin="2px 10px 2px 16px"
                                    src={proImageUrl + row.img_url}
                                    onError={() => errorImage(row.id)}
                                  />
                                ) : (
                                  <InitialsText>
                                    {getInitials(row.name)}
                                  </InitialsText>
                                )}
                                {row.name}
                              </DropdownText>
                            ))}
                          </>
                        ) : (
                          <DropdownText
                            width="100%"
                            border="none"
                            flexCenterVertical
                            padding="0 16px"
                          >
                            {appointmentData["office"] !== ""
                              ? "No Results Found"
                              : "Select an office, speciality and appointment type"}
                          </DropdownText>
                        )}
                      </>
                    )}
                  </DropdownDiv>
                )}
              </>
            )} */}
          </Div>
        </Div>

        {/* Provider End */}

        <Div
          margin="20px 0px 0px 0px"
          display="flex"
          alignItems="start"
          height={appointmentData["apttime"] === "" ? "100px" : "auto"}
          ref={timeDivRef}
          position="relative"
        >
          {appointmentData["showSelector"] ? (
            <>
              <Div flexCenterVertical width="45%">
                <NormalImage
                  loading="lazy"
                  src={dateIcon}
                  height="16px"
                  width="16px"
                />
                <Text
                  fontSize="13px"
                  fontWeight="400"
                  width="auto"
                  margin="0px 0px 0px 12px"
                >
                  Time and date
                </Text>
                {appointmentData["timeSelected"] && (
                  <HiddenButton
                    cursor="pointer"
                    mediaWidth="1100px"
                    onClick={() => dispatch(clearInput("time"))}
                  >
                    Edit
                  </HiddenButton>
                )}
              </Div>
              <>
                {!appointmentData["timeSelected"] ? (
                  <Div width="55%" height="100%" position="relative">
                    <InputInlineText
                      onClick={() => timeRef.current.focus()}
                      id="aptLst_napt-p_dt"
                    >
                      Select Time &#38; Date
                    </InputInlineText>

                    <AppointmentInput3
                      errorBorder={
                        timeError["noTime"] || timeError["noProvider"]
                      }
                      backgroundImage={clockLogo}
                      onClick={() =>
                        dispatch(
                          changeAppointmentData({
                            name: "showSelector",
                            value: false,
                          })
                        )
                      }
                      onFocus={() =>
                        dispatch(
                          changeAppointmentData({
                            name: "showSelector",
                            value: false,
                          })
                        )
                      }
                      name="time"
                      ref={timeRef}
                    />
                    {(timeError["noTime"] || timeError["noProvider"]) && (
                      <Text1
                        color="#F65160"
                        margin="2px 0px 0px 0px"
                        className={
                          timeError["noTime"]
                            ? "availabilityerr"
                            : "availabilityerr1"
                        }
                        width="100%"
                        align="center"
                      >
                        {timeError["noTime"]
                          ? "Select a time &s date"
                          : "Select a provider to check availablity"}
                      </Text1>
                    )}
                  </Div>
                ) : (
                  <SelectedOffice Type="time" />
                )}
              </>
            </>
          ) : (
            <TimeDateDiv ref={timeSelectRef}>
              <TimeDateSelector />
            </TimeDateDiv>
          )}
        </Div>

        <Div margin="20px 0px 0px 0px" height="67px" flexCenterBetween>
          <Div flexCenterVertical>
            <NormalImage
              loading="lazy"
              src={reasonIcon}
              height="16px"
              width="16px"
            />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Reason for Visit
            </Text>
          </Div>
          <ReasonInput
            placeholder="Enter reason"
            value={appointmentData["reason"]}
            onChange={(e) =>
              dispatch(
                changeAppointmentData({ name: "reason", value: e.target.value })
              )
            }
            id="aptLst_napt-tare_rson"
          />
        </Div>

        <Div width="100%" display="flex" justifyContent="end">
          <Button
            marginTop="37px"
            marginBottom="16px"
            height="40px"
            background="#2C7BE5"
            hoverBackColor="#005FB2"
            color=" #FFF"
            borderRadius="4px"
            width="55%"
            name="submit_button"
            className="save_active"
            disabled={savebtndisabled}
            onClick={saveAppointment}
            id="aptLst_napt-btn_smit"
          >
            Submit
          </Button>
        </Div>
      </DivVariant2>
    </Div>
  );
};
// commit
export default AddAppointment;
