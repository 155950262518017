import styled from "styled-components";

export const Container1 = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  padding: ${(p) => p.padding && p.padding};
`;
export const TabPanel = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding: ${(p) => p.padding && p.padding};
`;
export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "18px")};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? " #407bff" : "#8D98AF")};
  cursor: pointer;
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 76px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 77px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(0px);
                          width: 76px;
                        }
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 77px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(97px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(98px);
                          width: 73px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(98px);
                          width: 73px;
                        }
                         }
               `;
    }
  }}
`;

// my profile styles//

export const Hrline = styled.hr`
  border: 1px solid #d8e0f0;
  margin-top: ${(props) => (props.mt ? props.mt : "20px")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "20px")};
  margin-left: ${(p) => (p.mmarginLeft ? p.marginLeft : "-40px")};
  border-bottom: none;
`;

export const Imagecalendar = styled.img`
  position: absolute;
  object-fit: contain;
  cursor: pointer;
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  left: ${(p) => p.left && p.left};
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 3px;
`;
export const FieldHead = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2e2e2e;
  font-family: "Open Sans", sans-serif;
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin: ${(p) => p.margin && p.margin};
  width: ${(p) => p.width && p.width};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
`;
export const FileInput = styled.input`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "133px")};
  position: absolute;
  opacity: 0;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer !important")};
  margin-top: -52px;
  width: 314px;
  .not-allowed-cursor {
    cursor: not-allowed;
  }
`;
export const TitleHead = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #2e2e2e;
  font-family: "Open Sans", sans-serif;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;
export const Span1 = styled.span`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  color: ${(p) => p.color && p.color};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  overflow: ${(p) => p.overflow && p.overflow};
`;
export const Image = styled.img`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  color: ${(p) => p.color && p.color};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  cursor: ${(p) => p.cursor && p.cursor};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  &.dropdownimg {
    @media screen and (max-width: 950px) {
      right: 32px;
      top: 16px;
    }
    @media screen and (max-width: 750px) {
      right: 32px;
      top: 16px;
    }
  }
`;
