import { call, put } from "redux-saga/effects";
import { SetHomeWorkLists } from "../../StateManagement/Reducers/HomeWorkState";
import { HomeWorkListApi, UpdateWatchedApi } from "../Apis/HomeWorkApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

export function* HomeWorkLists({ payload }) {
  let type = payload;
  try {
    const res = yield call(HomeWorkListApi, type);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "westside");
      yield put(SetHomeWorkLists(decryptResponse));
    }
  } catch (e) {
    yield put(SetHomeWorkLists([]));
    console.log(e.message);
  }
}

export function* UpdateWatched({ payload }) {
  const credential = payload;
  let encryptdata = Encrypt_Value(credential, "westside");
  try {
    const res = yield call(UpdateWatchedApi, encryptdata);
    if (res.status === 200) {
      const resAfter = yield call(HomeWorkListApi, "");
      if (res.status === 200) {
        const decryptRes = Decrypt_Value(resAfter.data, "westside");
        yield put(SetHomeWorkLists(decryptRes));
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
