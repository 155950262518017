import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import {
  changeAppointmentData,
  changeSlider,
  changeErrorObject,
  changeTime,
  getPortalAppAvailablity,
} from "../../../StateManagement/Reducers/AppointmentsState";

import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import {
  DateInput,
  DateChangeButton,
  SlideContainer,
  Slicedes,
  Slide,
  TimePills,
  NextButton,
  TimeSelectorDiv,
} from "./Styles";
import { Div, NormalImage, Text } from "../../StyledComponents";

import Loading from "../../StyledComponents/Loading";
import leftIcon from "../../../Assets/images/left_arrow_icon.png";
import rightIcon from "../../../Assets/images/right_arrow_icon.png";
import axiosInst from "../../../Config";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <DateInput onClick={onClick} ref={ref} onChange={onChange}>
    {value}
  </DateInput>
));

const TimeDateSelector = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();

  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const sliderObject = useSelector((state) => state.Appointments.sliderObject);
  const loader = useSelector((state) => state.Appointments.loadAvailablity);
  const [workDays, setworkDays] = useState(0);
  const PortalAppAvailability = useSelector(
    (state) => state.Appointments.portalAppAvailablity
  );
  const Availablity = useSelector(
    (state) => state.Appointments.providerAvailablity
  );

  const appointmentDate = moment(appointmentData["aptdate"]);
  const currentDate = moment().startOf("day");

  // Determine if the button should be disabled
  const isDisabled = appointmentDate.isSame(currentDate);

  const checkTimeAvailablity = (x, value) => {
    const givenTime = moment(
      appointmentData["aptdate"] + " " + x,
      "YYYY-MM-DD hh:mm a"
    );
    const currentTime = moment();
    const a = moment(givenTime).diff(currentTime, "minutes");

    if (a < 0) {
      return true;
    } else {
      if (value) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();

    if (workDays === 2) {
      return day !== 0 && day !== 6;
    } else if (workDays === 1) {
      return day !== 0;
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(getPortalAppAvailablity());
    axiosInst
      .get("/westside/get/calendarsettingdata")
      .then((response) => {
        let temp_role_cal = Decrypt_Value(response.data, "westside");
        setworkDays(temp_role_cal.cal_drop);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // ------------------------------ State and Variables End--------------------------------------------------- //

  return (
    <>
      <TimeSelectorDiv>
        <DateChangeButton
          leftArrow
          disabled={isDisabled}
          onClick={() => {
            const appointmentDate = moment(appointmentData["aptdate"]);

            // Subtract one day from the appointment date
            let result = appointmentDate.subtract(1, "d");

            // Get the current date
            const currentDate = moment().startOf("day");

            if (!result.isBefore(currentDate)) {
              let a = result.format("YYYY-MM-DD");

              dispatch(changeAppointmentData({ name: "aptdate", value: a }));
            }
          }}
        >
          <NormalImage
            loading="lazy"
            src={leftIcon}
            height="55.55%"
            width="55.55%"
            border="none"
          />
        </DateChangeButton>
        <DatePicker
          selected={new Date(appointmentData["aptdate"])}
          dateFormat="MMM dd,yyyy"
          minDate={new Date()}
          onChange={(date) => {
            let a = moment(date).format("YYYY-MM-DD");
            dispatch(changeAppointmentData({ name: "aptdate", value: a }));
          }}
          value={new Date(appointmentData["aptdate"])}
          customInput={<CustomInput />}
          filterDate={isWeekday}
        />

        <DateChangeButton
          rightArrow
          onClick={() => {
            let result = moment(appointmentData["aptdate"]).add(1, "d");
            let a = result.format("YYYY-MM-DD");
            // if (a <= PortalAppAvailability["EndDate"]) {
            dispatch(changeAppointmentData({ name: "aptdate", value: a }));
            // }
          }}
        >
          <NormalImage
            loading="lazy"
            src={rightIcon}
            height="55.55%"
            width="55.55%"
          />
        </DateChangeButton>
      </TimeSelectorDiv>
      <SlideContainer>
        {!loader && Object.keys(Availablity).length > 0 ? (
          <>
            <Div
              width="100%"
              height="31%"
              position="relative"
              padding="8px 0px"
            >
              <Text align="left" fontSize="14px" fontWeight="500">
                Morning
              </Text>
              <Div
                width="100%"
                height="calc(100% - 20px)"
                flexCenterVertical
                margin="8px 0px 0px"
                position="relative"
              >
                <Slicedes>
                  <Slide
                    width="300%"
                    slideLeft={sliderObject["morning"]}
                    absoluteLeft={
                      sliderObject["morning"] === 1
                        ? "-100%"
                        : sliderObject["morning"] === 2
                        ? "-200%"
                        : "0%"
                    }
                  >
                    {Availablity["morning"].map((x, i) => (
                      <TimePills
                        width="14%"
                        Selected={
                          appointmentData["apttime"].toLowerCase() ===
                          x["time"].toLowerCase()
                        }
                        key={i}
                        value={x["time"]}
                        onClick={(e) => {
                          if (
                            appointmentData["provider"] === "" ||
                            appointmentData["office"] === ""
                          ) {
                            dispatch(
                              changeErrorObject({
                                name: "noProvider",
                                value: true,
                              })
                            );
                          } else {
                            dispatch(changeTime(e.target.value));
                            dispatch(
                              changeErrorObject({
                                name: "noTime",
                                value: false,
                              })
                            );
                          }
                        }}
                        disabled={checkTimeAvailablity(x["time"], x["disable"])}
                        id={`crt_appt-btn_tim${i}`}
                      >
                        {x["time"]}
                      </TimePills>
                    ))}
                  </Slide>
                </Slicedes>
                <NextButton
                  onClick={() =>
                    dispatch(
                      changeSlider({
                        name: "morning",
                        value: sliderObject["morning"] - 1,
                      })
                    )
                  }
                  cancel={sliderObject["morning"] === 0}
                  disabled={sliderObject["morning"] === 0}
                >
                  <AiOutlineDoubleLeft />
                </NextButton>
                <NextButton
                  onClick={() =>
                    dispatch(
                      changeSlider({
                        name: "morning",
                        value: sliderObject["morning"] + 1,
                      })
                    )
                  }
                  cancel={sliderObject["morning"] === 2}
                  disabled={sliderObject["morning"] === 2}
                >
                  <AiOutlineDoubleRight />
                </NextButton>
              </Div>
            </Div>

            <Div
              width="100%"
              height="31%"
              position="relative"
              padding="8px 0px"
            >
              <Text align="left" fontSize="14px" fontWeight="500">
                Afternoon
              </Text>
              <Div
                width="100%"
                height="calc(100% - 22px)"
                flexCenterVertical
                margin="8px 0px 0px"
                position="relative"
              >
                <Slicedes>
                  <Slide
                    slideLeft={sliderObject["afternoon"]}
                    width="300%"
                    absoluteLeft={
                      sliderObject["afternoon"] === 1
                        ? "-100%"
                        : sliderObject["afternoon"] === 2
                        ? "-200%"
                        : "0%"
                    }
                  >
                    {Availablity["afternoon"].map((x, i) => (
                      <TimePills
                        width="10.5%"
                        Selected={
                          appointmentData["apttime"].toLowerCase() ===
                          x["time"].toLowerCase()
                        }
                        key={i}
                        value={x["time"]}
                        disabled={checkTimeAvailablity(x["time"], x["disable"])}
                        onClick={(e) => {
                          if (
                            appointmentData["provider"] === "" ||
                            appointmentData["office"] === ""
                          ) {
                            dispatch(
                              changeErrorObject({
                                name: "noProvider",
                                value: true,
                              })
                            );
                          } else {
                            dispatch(changeTime(e.target.value));
                            dispatch(
                              changeErrorObject({
                                name: "noTime",
                                value: false,
                              })
                            );
                          }
                        }}
                      >
                        {x["time"]}
                      </TimePills>
                    ))}
                  </Slide>
                </Slicedes>
                <NextButton
                  onClick={() =>
                    dispatch(
                      changeSlider({
                        name: "afternoon",
                        value: sliderObject["afternoon"] - 1,
                      })
                    )
                  }
                  cancel={sliderObject["afternoon"] === 0}
                  disabled={sliderObject["afternoon"] === 0}
                >
                  <AiOutlineDoubleLeft />
                </NextButton>
                <NextButton
                  onClick={() =>
                    dispatch(
                      changeSlider({
                        name: "afternoon",
                        value: sliderObject["afternoon"] + 1,
                      })
                    )
                  }
                  cancel={sliderObject["afternoon"] === 2}
                  disabled={sliderObject["afternoon"] === 2}
                >
                  <AiOutlineDoubleRight />
                </NextButton>
              </Div>
            </Div>

            <Div
              width="100%"
              height="31%"
              position="relative"
              padding="8px 0px"
            >
              <Text align="left" fontSize="14px" fontWeight="500">
                Evening
              </Text>
              <Div
                width="100%"
                height="calc(100% - 22px)"
                flexCenterVertical
                margin="8px 0px 0px"
                position="relative"
              >
                <Slicedes>
                  <Slide
                    slideLeft={sliderObject["evening"]}
                    width="300%"
                    absoluteLeft={
                      sliderObject["evening"] === 1
                        ? "-100%"
                        : sliderObject["evening"] === 2
                        ? "-200%"
                        : "0%"
                    }
                  >
                    {Availablity["evening"].map((x, i) => (
                      <TimePills
                        width="10.5%"
                        key={i}
                        value={x["time"]}
                        onClick={(e) => {
                          if (
                            appointmentData["provider"] === "" ||
                            appointmentData["office"] === ""
                          ) {
                            dispatch(
                              changeErrorObject({
                                name: "noProvider",
                                value: true,
                              })
                            );
                          } else {
                            dispatch(changeTime(e.target.value));
                            dispatch(
                              changeErrorObject({
                                name: "noTime",
                                value: false,
                              })
                            );
                          }
                        }}
                        Selected={
                          appointmentData["apttime"].toLowerCase() ===
                          x["time"].toLowerCase()
                        }
                        disabled={checkTimeAvailablity(x["time"], x["disable"])}
                      >
                        {x["time"]}
                      </TimePills>
                    ))}
                  </Slide>
                </Slicedes>
                <NextButton
                  onClick={() =>
                    dispatch(
                      changeSlider({
                        name: "evening",
                        value: sliderObject["evening"] - 1,
                      })
                    )
                  }
                  cancel={sliderObject["evening"] === 0}
                  disabled={sliderObject["evening"] === 0}
                >
                  <AiOutlineDoubleLeft />
                </NextButton>
                <NextButton
                  onClick={() =>
                    dispatch(
                      changeSlider({
                        name: "evening",
                        value: sliderObject["evening"] + 1,
                      })
                    )
                  }
                  cancel={sliderObject["evening"] === 2}
                  disabled={sliderObject["evening"] === 2}
                >
                  <AiOutlineDoubleRight />
                </NextButton>
              </Div>
            </Div>
          </>
        ) : (
          <Loading position="absolute" height="100%" />
        )}
      </SlideContainer>
    </>
  );
};

export default TimeDateSelector;
