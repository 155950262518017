import React, { useState, useEffect } from "react";
import { BodyContainer } from "../StyledComponents";
import TopNav from "../TopNav";
import {
  getInsertEventType,
  GetEventTypeData,
} from "../../StateManagement/Reducers/SettingState";
import { useSelector, useDispatch } from "react-redux";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { Div, EblPatPort, CheckBoxEbl, CheckBoxLabel, H4 } from "./styles";
import { Button } from "../StyledComponents";
const CalendarIntegration = () => {
  const dispatch = useDispatch();
  //---------------------------------- State End ----------------------------------//
  const [patportcheck, setpatportcheck] = useState(false);
  const [outlookCheck, setoutlookCheck] = useState(false);
  const [icalCheck, seticalCheck] = useState(false);
  const pid =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "westside");
  const CalEventData = useSelector((state) => state.Setting.EventTypeData);
  //----------------------------- Functionality Start -----------------------------//
  const handlePortal = () => {
    setpatportcheck(!patportcheck);
  };
  const handleOutlookCheck = () => {
    setoutlookCheck(!outlookCheck);
  };

  const handleIcalCheck = () => {
    seticalCheck(!icalCheck);
  };

  const SaveCalendar = async () => {
    const stringParts = [];

    if (patportcheck === true) {
      stringParts.push("Goo");
    }

    if (outlookCheck === true) {
      stringParts.push("Out");
    }

    if (icalCheck === true) {
      stringParts.push("Ical");
    }
    const concatenatedString = stringParts.join(",");

    dispatch(getInsertEventType({ EventType: concatenatedString, pid: pid }));
  };
  //----------------------------- Functionality End -----------------------------//

  //------------------------------ UseEffectt Start ------------------------------//

  useEffect(() => {
    dispatch(GetEventTypeData());
  }, []);
  useEffect(() => {
    if (
      CalEventData !== "" &&
      CalEventData !== null &&
      CalEventData !== undefined &&
      CalEventData.length > 0
    ) {
      const events = CalEventData.split(",");

      if (events.includes("Goo")) {
        setpatportcheck(true);
      } else {
        setpatportcheck(false);
      }

      if (events.includes("Out")) {
        setoutlookCheck(true);
      } else {
        setoutlookCheck(false);
      }

      if (events.includes("Ical")) {
        seticalCheck(true);
      } else {
        seticalCheck(false);
      }
    }
  }, [CalEventData]);

  //------------------------------ UseEffectt End ------------------------------//

  return (
    <>
      <TopNav title="Calendar Integration" />
      <BodyContainer paddingLeft="40px" paddingTop="4px" paddingBottom="10px">
        <Div margin="20px">
          <EblPatPort>
            <CheckBoxEbl
              id="checkbox"
              type="checkbox"
              checked={patportcheck}
              onChange={() => handlePortal()}
            />

            <CheckBoxLabel
              width="42px"
              height="23px"
              afrWidth="17px"
              afrHeight="17px"
              afrMrgn="3px"
            />
            <H4
              weight="500"
              color="#000"
              size="16px"
              afrWidth="17px"
              afrHeight="17px"
              padding="1px 0 0 8px"
            >
              Google
            </H4>
          </EblPatPort>
          <EblPatPort>
            <CheckBoxEbl
              id="checkbox"
              type="checkbox"
              checked={outlookCheck}
              onChange={() => handleOutlookCheck()}
            />

            <CheckBoxLabel
              width="42px"
              height="23px"
              afrWidth="17px"
              afrHeight="17px"
              afrMrgn="3px"
            />
            <H4
              weight="500"
              color="#000"
              size="16px"
              afrWidth="17px"
              afrHeight="17px"
              padding="1px 0 0 8px"
            >
              Outlook/Hotmail
            </H4>
          </EblPatPort>
          <EblPatPort>
            <CheckBoxEbl
              id="checkbox"
              type="checkbox"
              checked={icalCheck}
              onChange={() => handleIcalCheck()}
            />

            <CheckBoxLabel
              width="42px"
              height="23px"
              afrWidth="17px"
              afrHeight="17px"
              afrMrgn="3px"
            />
            <H4
              weight="500"
              color="#000"
              size="16px"
              afrWidth="17px"
              afrHeight="17px"
              padding="1px 0 0 8px"
            >
              iCal(Apple Calendar)
            </H4>
          </EblPatPort>
        </Div>
        <Div margin="0px 0px 0px 20px" dis="flex">
          <Button
            padding="12px 20px"
            height="40px"
            background="#2C7BE5"
            hoverBackColor="#005FB2"
            color="#fff"
            fontWeight="500"
            borderRadius="4px"
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            display="flex"
            svgfontSize="16px"
            svgmarginRight="10px"
            className="save_active"
            onClick={SaveCalendar}
            id="doc_upd-btn"
          >
            Save information
          </Button>
        </Div>
      </BodyContainer>
    </>
  );
};

export default CalendarIntegration;
